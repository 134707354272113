import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TotalTableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="header no-top-border no-left-border">
        {t('CustomsPayments:PaymentTypeColumntTitle')}
      </div>
      <div className="header no-top-border">{t('CustomsPayments:RateColumnTitle')}</div>
      <div className="header no-top-border">{t('CustomsPayments:RubPriceColumnTitle')}</div>
    </>
  );
};

export default TotalTableHeader;
