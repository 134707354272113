import TerminalsGroupInfo from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsGroupInfo';
import TerminalsInfoHeader from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoHeader';
import { totalTerminalsPrice } from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/helpers';
import { WithRouteProductContextData } from '@components/pages/Products/components/RouteProduct/types';
import WithRouteProductContext from '@components/pages/Products/components/RouteProduct/WithRouteProductContext';
import { TaxNameProps } from '@helpers/getTaxNames';
import getTerminalsToDisplay from '@helpers/getTerminalsFromStep';
import { Order } from '@services/requests/orderService/interface';
import { RouteStep } from '@services/requests/orderService/types';
import React, { FC } from 'react';
import OfferAccordionGroup from 'src/pages/OfferDetail/components/RowItems';

type TerminalsInfoProps = WithRouteProductContextData<{
  steps: RouteStep[];
  taxNames: TaxNameProps[];
  order: Order;
}>;

/**
 * Компонент выводит блок информации по терминалам. Группирует их для
 * объединения расходов.
 *
 * @param props
 */
const TerminalsInfo: FC<TerminalsInfoProps> = (props: TerminalsInfoProps) => {
  const {
    steps,
    currencyRoute,
    productData: {
      terminals: terminalsBranchItems,
      cargoParameters: { endTransportingConditionId, startTransportingConditionId },
    },
    realTimeBaseData,
    taxNames,
    order,
  } = props;

  const terminalsToDisplay = getTerminalsToDisplay(steps, realTimeBaseData);
  const fullPrice = totalTerminalsPrice(terminalsToDisplay);

  return (
    <OfferAccordionGroup
      header={
        <TerminalsInfoHeader
          currencyCode={currencyRoute.code}
          fullPrice={fullPrice}
          order={order}
        />
      }
      additionalInfo={terminalsToDisplay.map((terminals) => (
        <TerminalsGroupInfo
          key={terminals[0].terminal.id}
          terminalGroup={terminals}
          terminals={terminalsBranchItems}
          taxNames={taxNames}
          endTransportingConditionId={endTransportingConditionId}
          startTransportingConditionId={startTransportingConditionId}
          order={order}
        />
      ))}
    />
  );
};

export default WithRouteProductContext(TerminalsInfo);
