import { getLocalizationById } from '@context/useMyRouting/helpers';
import { ServiceSummary } from '@pages/AdditionalServices/containers/methods/basket/getSummaryForServices';
import { Order } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryTotal } from './ServiceSectionDetail/SummaryTotal';

export interface ServiceSummaryProps {
  summary: ServiceSummary;
  title: string;
  isTotal?: boolean;
  order: Order;
  products?: OrderProduct[];
}

const ServiceSummaryComp: FC<ServiceSummaryProps> = (props) => {
  const { summary, title, isTotal = false, order } = props;
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail', 'Common'], { lng: langCode });

  return (
    <div
      className={clsx('a-service-basket--summary', 'kendo-pdf--prevent-split', {
        'total-summary': isTotal,
      })}
    >
      <SummaryTotal {...props} products={props.products ?? []} />
      <div className="a-service-basket--summary-conversions">
        <div>{t('CartSummary:CurrencyDetail')}:</div>
        <div className="a-service-basket--summary-conversions-list">
          {Object.keys(summary.Currencies).map((currency) => {
            if (summary.Currencies[currency] === 0) {
              return null;
            }

            return (
              <div key={currency}>
                <div>{currency}:</div>
                <div>{summary.Currencies[currency].toLocaleString()}</div>
              </div>
            );
          })}
        </div>
        <div>
          <div>{t('CartSummary:ForeignExchangeExpenses')}:</div>
          <div>
            {summary.Conversion.toLocaleString()} {summary.TargetCurrency}
          </div>
        </div>
      </div>
      <div className="a-service-basket--summary--total">
        <div>{title}:</div>
        <div>
          {summary.Total.toLocaleString()} {summary.TargetCurrency}
        </div>
      </div>
    </div>
  );
};

export default ServiceSummaryComp;
