import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import CargoDeclarationTemplate from "@pages/AdditionalServices/components/Service/CargoDeclarationTemplate";
import ServiceInCargoDeclarationTemplate
  from "@pages/AdditionalServices/components/Service/ServiceInCargoDeclarationTemplate";
import AdditionListTemplate from "@pages/AdditionalServices/components/Service/AdditionListTemplate";
import ServiceInAdditionListTemplate from "@pages/AdditionalServices/components/Service/ServiceInAdditionListTemplate";
import ServiceInListingTemplate from "@pages/AdditionalServices/components/Service/ServiceInListingTemplate";
import {Service, ServiceFileData} from "@onlog-public/additional-services-types";
import VerticalTableTemplate from "@pages/AdditionalServices/components/Service/VerticalTableTemplate";
import getServiceTotalPrice from "@pages/AdditionalServices/components/Service/getServiceTotalPrice";
import CustomsPaymentsTemplate from "./CustomsPaymentsTemplate";

/**
 * isNeedHideServiceWithPriceByRequest возвращает флаг того, что необходимо скрывать
 * услугу, для которой не задана стоимость.
 * @param s
 */
const isNeedHideServiceWithPriceByRequest = (s: Service) => {
  switch (s.template) {
    case "verticalTable":
      return s.settings.TemplateSettings.VerticalTable.IsNotVisibleWhenPriceByRequest
    case "serviceInVerticalTable":
      return s.settings.TemplateSettings.ServiceInVerticalTable.IsNotVisibleWhenPriceByRequest
    case "serviceInListing":
      return s.settings.TemplateSettings.ServiceInList.IsNotVisibleWhenPriceByRequest
    case "serviceInAdditionList":
      return s.settings.TemplateSettings.ServiceInAdditionList.IsNotVisibleWhenPriceByRequest
    case "additionList":
      return s.settings.TemplateSettings.AdditionList.IsNotVisibleWhenPriceByRequest
    case "cargoDeclaration":
      return s.settings.TemplateSettings.CargoDeclaration.IsNotVisibleWhenPriceByRequest
    case "serviceInCargoDeclaration":
      return s.settings.TemplateSettings.ServiceInCargoDeclaration.IsNotVisibleWhenPriceByRequest
  }

  return false
}

/**
 * ServiceComp реализует компонент вывода услуг
 * @param props
 */
const ServiceComp: FC<ServiceProps> = props => {
  /**
   * Подмешиваем в глобальные файлы те, которые привязаны к
   * открытому варианту.
   */
  const {variant, globalFiles, ...other} = props
  const filesMap: { [K in string]: ServiceFileData } = {}
  Array.from([...globalFiles, ...variant.file_id]).map(f => filesMap[f.id] = f)

  const childProps: ServiceProps = {
    ...other,
    variant,
    globalFiles: Object.values(filesMap),
  }

  const serviceTotalPrice = getServiceTotalPrice(props.serviceCalculations)
  const isNeedHide = isNeedHideServiceWithPriceByRequest(props.service)

  if (isNeedHide && serviceTotalPrice < 0) {
    return null
  }

  switch (props.service.template) {
    case "cargoDeclaration":
      return <CargoDeclarationTemplate {...childProps} />
    case "serviceInCargoDeclaration":
      return <ServiceInCargoDeclarationTemplate {...childProps} />
    case "additionList":
      return <AdditionListTemplate {...childProps} />
    case "serviceInAdditionList":
      return <ServiceInAdditionListTemplate {...childProps} />
    case "serviceInListing":
      return <ServiceInListingTemplate {...childProps} />
    case "verticalTable":
      return <VerticalTableTemplate {...childProps} />
	case "customsPayments":
	  return <CustomsPaymentsTemplate {...childProps} />
  }

  return null
}

export default ServiceComp