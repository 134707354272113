import MButton from '@assets/mui/@material-extend/MButton';
import {
  BitrixDisableUserGuard,
  BitrixHiddeUserGuard,
} from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import { useBitrix } from '@context/useBitrixUser/useBitrix';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import PrintIcon from '@mui/icons-material/Print';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getContainerRentDescription,
  getRouteInformationList,
} from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/RouteListItem/helpers';
import useBasket, { isProductEquals } from '@pages/Basket/useBasket';
import { productService } from '@services/collectors/ProductService';
import { OrderProductType } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { RouteResult, RoutesGroup } from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { SortData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import getEnv from '@settings/getEnv';
import { getLocalization } from '@settings/services/helpers/getLocalization';
import { isEqualsForMemo } from '@settings/services/types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { MouseEventHandler, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDelivery from '../../../useDelivery';
import { calculateTax, formatPrice } from '../helpers';
import { RouteInformation } from './RouteInformation';
import RoutesDetails from './RoutesDetails';
import Container from './StyledComponents';

/**
 * Пропсы компонента
 */
export type RouteListItemProps = {
  route: RoutesGroup;
  by: SortData['by'];
  firstItem: boolean;
  isRouteNotComplete: boolean;
  onClickDetail(route: OrderProduct): void;
};

/**
 * Болк перерендера
 * @param prevProps
 * @param nextProps
 */
const isEquals: isEqualsForMemo<RouteListItemProps> = (prevProps, nextProps) =>
  JSON.stringify([prevProps.route, prevProps.by, prevProps.firstItem]) ===
  JSON.stringify([nextProps.route, nextProps.by, nextProps.firstItem]);

/**
 * Функция генерирует краткое описание маршрута для переданных данных
 * @param props
 */
const buildDescriptionText = (props: RouteListItemProps & { primaryLangId: string }) => {
  const { useBaseDate, useRouteParams, useTerminals, useStartTerminals, useEndTerminals } =
    useDelivery();

  const { t } = useTranslation('RoutesListComponent');

  const { route, primaryLangId } = props;
  const [transitTerminals] = useTerminals().useState();
  const [endTerminals] = useEndTerminals().useState();
  const [startTerminals] = useStartTerminals().useState();
  const [baseData] = useBaseDate().useState();
  const [routeParams] = useRouteParams().useState();

  const terminals = [...startTerminals, ...transitTerminals, ...endTerminals];

  if (!routeParams || !baseData) {
    return '';
  }

  const steps = route.routes[0].route.steps;

  const { carriers, loadingTransportConditions, unloadingTransportConditions } = baseData;
  const { startLocation, endLocation, startTransportingCondition, endTransportingCondition } =
    routeParams;

  const startLoadingCondition = steps[0].shoulderOffer.loading_condition_id;
  const endUnloadingCondition = steps[steps.length - 1].shoulderOffer.unloading_condition_id;

  const loadingCondition = loadingTransportConditions.find((c) => c.id === startLoadingCondition);
  const unloadingCondition = unloadingTransportConditions.find(
    (c) => c.id === endUnloadingCondition
  );

  // Достаем терминалы отправления для каждого плеча
  const routeTerminals = route.routes[0].route.steps.map((step) =>
    terminals.find((t) => t.id === step.startTerminal?.id)
  );

  // Для конечного получаем терминал назначения если он есть
  const routeEndTerminal = terminals.find(
    (t) =>
      t.id === route.routes[0].route.steps[route.routes[0].route.steps.length - 1].endTerminal?.id
  );

  // Если терминал отправления не найден, то отправление со склада из локации
  // соответственно подменяем его на локацию
  routeTerminals[0] = routeTerminals[0] ? routeTerminals[0] : startLocation;

  // Если терминал назначения не задан, то назначение - склад в локации,
  // соответственно подменяем на локацию
  routeTerminals.push(routeEndTerminal || endLocation);

  // Обсчитываем локализацию для терминалов маршрута
  const terminalLocalizations = routeTerminals
    .filter((t) => !!t)
    .map((t) => {
      const terminal = t as BranchItem;

      return getLocalization(
        terminal.visibleName,
        terminal.localizedNamesArray.filter((m) => terminal.localizedNames.includes(m.id)),
        primaryLangId
      );
    });

  const containerRentDescription = getContainerRentDescription(
    route,
    t('RoutesListComponent:Rent')
  );

  // Генерируем части описания маршрута
  const descriptionParts = [
    // Если в маршруте задана аренда контейнера, то это SOC + аренда, в остальных
    // случаях будет COC
    routeParams.cargoType === 'container' ? containerRentDescription : '',

    // К локализованным терминалам дописываем через / перевозчика по тому
    // плечу, которое начинается с этого терминала.
    ...terminalLocalizations.map((term, idx) => {
      const isFirstLocation = idx === 0;
      const isLastLocation = idx === terminalLocalizations.length - 1;

      const carrierId = route.routes[0].route.steps[idx]?.shoulder?.carrier_id;
      const carrier = carriers.find((c) => c.id === carrierId);

      let newTerm = term;

      if (isFirstLocation) {
        newTerm = `(${loadingCondition.code}) ` + newTerm;
      } else if (isLastLocation) {
        newTerm = `(${unloadingCondition.code}) ` + newTerm;
      }

      const names: string[] = [newTerm];

      if (carrier) {
        names.push(
          getLocalization(carrier.default_name, carrier.localized_names_data, primaryLangId)
        );
      }

      return names.join(` / `);
    }),
  ];

  return `* ${descriptionParts.filter((i) => i !== '').join(` / `)}`;
};

/**
 * Компонент вывода карточки маршрута
 * @param props
 */
const RouteListItem = (props: RouteListItemProps) => {
  const {
    useBaseDate,
    useRouteParams,
    useTerminals,
    useEndTerminals,
    useStartTerminals,
    useIsArchiveLoading,
    useSettings,
    useRoutes,
    useFromBranch,
    useToBranch,
  } = useDelivery();
  const { useOrder, actions } = useBasket();
  const [isFullView, setFullView] = useState(false);
  const { t } = useTranslation(['RoutesListComponent', 'Common', 'OrderList', 'Actions']);

  const [{ bitrixUser }] = useBitrix().useBitrixUser().useState();
  const [transitTerminals] = useTerminals().useState();
  const [endTerminals] = useEndTerminals().useState();
  const [startTerminals] = useStartTerminals().useState();
  const [baseData] = useBaseDate().useState();
  const [routeParams] = useRouteParams().useState();
  const [startLocation] = useFromBranch().useState();
  const [endLocation] = useToBranch().useState();
  const [settings] = useSettings().useState();
  const [routes] = useRoutes().useState();
  const [isArchiveLoading] = useIsArchiveLoading().useState();
  const { route, onClickDetail, by, firstItem, isRouteNotComplete } = props;
  const { addToCart, createTempPreOrder } = actions;
  const [order] = useOrder().useState();
  const [localizationSettings] = useMyRouting().useSettings().useState();
  const { countryCode } = localizationSettings;
  const terminals = [...startTerminals, ...transitTerminals, ...endTerminals];

  if (!routeParams || !baseData) {
    return null;
  }

  function getProductData() {
    const { fullPrice } = route;
    const token = route.routeToken;
    const { cargoParameters, isNotCompleteResult } = routes;

    if (!startLocation || !endLocation || !baseData) {
      return;
    }

    const data: RouteResult = productService().Generate(
      routeParams,
      cargoParameters,
      startLocation,
      endLocation,
      route,
      terminals,
      baseData,
      order,
      isNotCompleteResult
    );

    return {
      product_type: 'route' as OrderProductType,
      data: JSON.stringify(data),
      token: token,
      amount: 1,
      price: fullPrice,
    };
  }

  const productData = getProductData();

  if (!productData) {
    return null;
  }

  /**
   * Обработчик создания PDF для маршрута
   */
  const createPDFHandler = async () => {
    const preorder = await createTempPreOrder(productData);
    const { REACT_APP_PDF_VIEW_ENDPOINT } = getEnv();
    window.open(`${REACT_APP_PDF_VIEW_ENDPOINT}/pre-order/${preorder.id}`, '_blank');
  };

  const inCart = !!order.products.find((p) => isProductEquals(p, productData));

  /**
   * Обработчик добавления в корзину
   */
  const add2CartHandler: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();

      if (!productData || !bitrixUser) {
        return;
      }

      addToCart(productData);
    },
    [addToCart, productData]
  );

  const fullTax = calculateTax(route);
  const { fullPrice } = route;

  const data: RouteResult = JSON.parse(productData.data);

  const validDateText = useMemo(() => {
    const dates = data.route.routes
      .map((r) => r.route.steps)
      .reduce((acc, steps) => [...acc, ...steps], [])
      .map((s) => s.shoulderOffer.active_to)
      .map((ds) => new Date(ds).getTime());
    const nearestDate = Math.min(...dates);
    return dayjs(nearestDate).format('DD.MM.YYYY ' + (countryCode === 'ru' ? 'г.' : ''));
  }, [data]);
  const routeDescription = buildDescriptionText({
    ...props,
    primaryLangId: settings.primaryLanguageId,
  });
  const isMobile = useMediaQuery('(max-width:600px)');

  const SortIndicator = () => (
    <div className={'sort-indicator'}>
      {by === 'price' && <span>{t('RoutesListComponent:TheCheapestRoutes')}</span>}
      {by === 'deliveryTime' && <span>{t('RoutesListComponent:TheFastestRoutes')}</span>}
    </div>
  );

  if (!startLocation || !endLocation) {
    return <></>;
  }

  const routeInformations = getRouteInformationList(baseData.contractors, route);

  const isRouterInformationExist = routeInformations.length > 0;

  const currency = baseData.currencies.find((c) => c.id === settings.currencyId);
  const isOneRouteStep =
    route.routes.length === 1 &&
    route.routes[0].route.steps.length === 1 &&
    route.routes[0].route.steps.map((step) => step.shoulder.shoulderSteps).flat().length === 0;

  return (
    <Container sx={{ padding: '28px 0 0 0', marginBottom: '24px' }}>
      {isRouteNotComplete && (
        <Tooltip title={t('RoutesListComponent:isRouteNotComplete')} placement="top">
          <div className="routes-not-complete">
            <ErrorOutlineIcon fontSize={'small'} />
          </div>
        </Tooltip>
      )}
      {firstItem && <SortIndicator />}
      <div className="top__content">
        <div
          className={'top__content--wrapper'}
          style={{ marginBottom: isOneRouteStep && !isRouterInformationExist ? '24px' : '0px' }}
        >
          <div className="top__content--left">
            <RoutesDetails
              route={route}
              routeParams={routeParams}
              isFullView={isFullView}
              setFullView={setFullView}
              baseData={baseData}
              primaryLanguageId={settings.secondaryLanguageId}
              terminals={terminals}
            />
            <div className={'price-line'}>
              <div className={'full__price'}>
                {formatPrice(fullPrice)} {currency && currency.glyph}
              </div>
              {!isMobile && (
                <div className={'taxes-wrapper'}>
                  <div className={'taxes'}>{t('RoutesListComponent:TaxesFees')}</div>
                  <div className={'taxes-price'}>
                    {formatPrice(fullTax)} {currency && currency.glyph}
                  </div>
                </div>
              )}
              {isMobile && (
                <div className={'taxes'}>
                  {t('RoutesListComponent:TaxesFees')} {formatPrice(fullTax)}{' '}
                  {currency && currency.glyph}
                </div>
              )}
            </div>
          </div>
          <div className="description">
            <BitrixHiddeUserGuard>
              <Tooltip title={routeDescription} arrow placement={'top'}>
                <div className={'description__text'}>{routeDescription}</div>
              </Tooltip>
            </BitrixHiddeUserGuard>
            <div>
              <span>
                {t('OrderList:itemCalcDate')} {validDateText}
              </span>
            </div>
          </div>
          <BitrixHiddeUserGuard>
            {isRouterInformationExist && (
              <RouteInformation
                routeInformations={routeInformations}
                isContentVisible={isFullView}
                onClickHeader={() => setFullView((state) => !state)}
              />
            )}
          </BitrixHiddeUserGuard>
        </div>
        <div className="top__content--right">
          <YaTargetWrapper target="calculator_moi_zakaz" noAuthTarget="calculator_moi_zakaz_noreg">
            <BitrixDisableUserGuard>
              <MButton
                onClick={add2CartHandler}
                className={clsx('add-to-card-action', { 'in-card': inCart })}
                variant={'outlined'}
                disabled={isArchiveLoading}
                color={inCart ? 'primary' : 'secondary'}
                fullWidth={true}
              >
                {!inCart ? t('RoutesListComponent:ToCart') : t('RoutesListComponent:inCart')}
              </MButton>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
          <YaTargetWrapper target="calculator_detal" noAuthTarget="calculator_detal_noreg">
            <BitrixDisableUserGuard>
              <MButton
                className={'offer-actions'}
                fullWidth={true}
                variant={'outlined'}
                color={'inherit'}
                onClick={() => onClickDetail(productData)}
              >
                {isMobile ? (
                  <FindInPageIcon color={'inherit'} sx={{ fontSize: '20px' }} />
                ) : (
                  t('Common:Detailing')
                )}
              </MButton>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
          <YaTargetWrapper target="calculator_detal" noAuthTarget="calculator_detal_noreg">
            <BitrixDisableUserGuard>
              <MButton
                className={'offer-actions'}
                onClick={createPDFHandler}
                fullWidth={true}
                color={'inherit'}
                variant={'outlined'}
              >
                {isMobile ? (
                  <PrintIcon color={'inherit'} sx={{ fontSize: '20px' }} />
                ) : (
                  t('Actions:Print')
                )}
              </MButton>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
          {/*<MButton*/}
          {/*  fullWidth={true}*/}
          {/*  variant={'outlined'}*/}
          {/*  size={'small'}*/}
          {/*  className={'offer-actions'}*/}
          {/*  disabled={true}*/}
          {/*  startIcon={!isMobile && <FavoriteIcon color={'inherit'} sx={{ fontSize: '20px' }} />}*/}
          {/*>*/}
          {/*  {isMobile ? (*/}
          {/*    <FavoriteIcon color={'inherit'} sx={{ fontSize: '20px' }} />*/}
          {/*  ) : (*/}
          {/*    t('Actions:ToFavorites')*/}
          {/*  )}*/}
          {/*</MButton>*/}
        </div>
      </div>
      {(!isOneRouteStep || isRouterInformationExist) && (
        <MButton
          onClick={() => setFullView((state) => !state)}
          variant={'outlined'}
          className={'switch'}
          color={isFullView ? 'primary' : 'inherit'}
          fullWidth={true}
        >
          <span>
            {isFullView && t('Actions:Collapse')}
            {!isFullView && t('Actions:Expand')}
          </span>
          {isFullView && <KeyboardArrowUpRoundedIcon sx={{ fontSize: '20px' }} />}
          {!isFullView && <KeyboardArrowDownRoundedIcon sx={{ fontSize: '20px' }} />}
        </MButton>
      )}
    </Container>
  );
};

export default memo(RouteListItem, isEquals);
