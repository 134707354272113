import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Service, ServiceDirectory } from '@onlog-public/additional-services-types';
import { ExciseItem, ImportItem } from '@services/requests/customsTaxService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { checkErrorExisting } from '../helpers/helpers';
import { CustomsPaymentsTemplateProduct, CustomsPaymentsTemplateStore } from '../types';

interface CountFieldsProps {
  selectedFee: ImportItem;
  selectedExcise: ExciseItem;
  invalidFields: string[];
  serviceTemplateStore: { [T in string]: any };
  productIndex: number;
  variant: ServiceDirectory;
  service: Service;
  onChangeProductValue: <T extends keyof CustomsPaymentsTemplateProduct>(
    key: T,
    value: CustomsPaymentsTemplateProduct[T]
  ) => void;
  onChangeServiceTemplateStore: (variant: string, service: string, store: any) => void;
}

function CountFields({
  selectedFee,
  selectedExcise,
  invalidFields,
  productIndex,
  serviceTemplateStore,
  service,
  variant,
  onChangeProductValue,
  onChangeServiceTemplateStore,
}: CountFieldsProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];
  const product = store.products[productIndex];

  const { productId, productsCount, maximumRetailPackagePrice, packagesCount } = product;

  const feeMeasureText = selectedFee
    ? ` ${selectedFee.Measure.Name}(${selectedFee.Measure.Razm})`
    : ``;

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';

  const validateCountFieldsHandler = <T extends keyof CustomsPaymentsTemplateProduct>(
    key: T,
    value: string
  ) => {
    const newStore = { ...store };

    const foundProductIndex = newStore.products.findIndex(
      (product) => product.productId === productId
    );

    if (foundProductIndex === -1) {
      return;
    }

    const changedProduct = { ...newStore.products[foundProductIndex] };

    const isAlreadyInvalid = changedProduct.invalidFields.includes(key);
    const isValueInvalid = value === '' || value === '0';

    if (isAlreadyInvalid) {
      return product;
    }
    const newInvalidFields = isValueInvalid
      ? [...changedProduct.invalidFields, key]
      : changedProduct.invalidFields.filter((errorKey) => errorKey !== key);

    changedProduct.invalidFields = newInvalidFields;
    newStore.products.splice(productIndex, 1, changedProduct);

    onChangeServiceTemplateStore(variant.id, service.id, { ...newStore });
  };

  return (
    <div className="count-wrap">
      {true && (
        <div className="result-item__col">
          <TextField
            fullWidth
            className="result-item__input"
            variant={'standard'}
            value={productsCount}
            focused={checkErrorExisting(['productsCount'], invalidFields)}
            error={checkErrorExisting(['productsCount'], invalidFields)}
            onChange={(event) => {
              onChangeProductValue('productsCount', event.target.value);
              validateCountFieldsHandler('productsCount', event.target.value);
            }}
            type={'number'}
            InputProps={{
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: 'grey[700]',
                      position: 'absolute',
                      pointerEvents: 'none',
                      fontSize: '14px',
                    }}
                    component={'span'}
                    variant={'h5'}
                  >
                    {t('CustomsPayments:ProductQuantityLabel')}
                    {feeMeasureText}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      {isSpecialFieldsVisible && (
        <div className="result-item__col">
          <TextField
            fullWidth
            className="result-item__input"
            variant={'standard'}
            value={packagesCount}
            focused={checkErrorExisting(['packagesCount'], invalidFields)}
            error={checkErrorExisting(['packagesCount'], invalidFields)}
            onChange={(event) => {
              onChangeProductValue('packagesCount', event.target.value);
              validateCountFieldsHandler('packagesCount', event.target.value);
            }}
            type={'number'}
            InputProps={{
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: 'grey[700]',
                      position: 'absolute',
                      pointerEvents: 'none',
                      fontSize: '14px',
                    }}
                    component={'span'}
                    variant={'h5'}
                  >
                    {t('CustomsPayments:PackagesCount')}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      {isSpecialFieldsVisible && (
        <div className="result-item__col">
          <TextField
            fullWidth
            className="result-item__input"
            variant={'standard'}
            focused={checkErrorExisting(['maximumRetailPackagePrice'], invalidFields)}
            error={checkErrorExisting(['maximumRetailPackagePrice'], invalidFields)}
            value={maximumRetailPackagePrice}
            onChange={(event) => {
              onChangeProductValue('maximumRetailPackagePrice', event.target.value);
              validateCountFieldsHandler('maximumRetailPackagePrice', event.target.value);
            }}
            type={'number'}
            InputProps={{
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: 'grey[700]',
                      position: 'absolute',
                      pointerEvents: 'none',
                      fontSize: '14px',
                    }}
                    component={'span'}
                    variant={'h5'}
                  >
                    {t('CustomsPayments:PackageMaxPriceLabel')}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}

export { CountFields };
