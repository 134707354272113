import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceProps } from '../../types';

interface CustomsPaymentsDetailingOverallProps extends ServiceProps {}

function CustomsPaymentsDetailingOverall({
  servicesFields,
  servicesFieldValues,
  globalFieldValues,
  globalFields,
  service,
  options,
}: CustomsPaymentsDetailingOverallProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const fields = [...servicesFields, ...globalFields];
  const fieldValues = { ...globalFieldValues, ...servicesFieldValues };
  const settings = service.settings.TemplateSettings.CustomsPayments;

  const customsModeField = fields.find((f) => f.code === settings.CustomsModeField);
  const contractCurrencyField = fields.find((f) => f.code === settings.ContractCurrencyField);

  const customsMode =
    options[customsModeField.code].find((option) => option.value === customsModeField.value)
      ?.value ?? 1;

  const contractCurrency = options[contractCurrencyField.code].find(
    (currency) => currency.value === fieldValues[contractCurrencyField.code]
  );

  const borderDeliveryCurrencyField = fields.find(
    (f) => f.code === settings.BorderDeliveryCurrencyField
  );
  const otherExpensesCurrencyField = fields.find(
    (f) => f.code === settings.OtherExpensesCurrencyField
  );
  const borderDeliverySumField = fields.find((f) => f.code === settings.BorderDeliverySumField);
  const otherExpensesSumField = fields.find((f) => f.code === settings.OtherExpensesSumField);
  const borderDeliveryCurrency = options[borderDeliveryCurrencyField.code].find(
    (currency) => currency.value === fieldValues[borderDeliveryCurrencyField.code]
  );
  const otherExpensesCurrency = options[otherExpensesCurrencyField.code].find(
    (currency) => currency.value === fieldValues[otherExpensesCurrencyField.code]
  );

  const CUSTOMS_MODES = [
    {
      value: '1',
      label: t('CustomsPayments:Import'),
    },
    {
      value: '2',
      label: t('CustomsPayments:Export'),
    },
  ];

  const formattedCustomsMode = customsMode === 1 ? CUSTOMS_MODES[0].label : CUSTOMS_MODES[1].label;

  return (
    <div className="overall-wrap">
      <div className="overall-col">
        <div className="overall-item">
          <div className="overall-item__title">
            {t('CustomsPayments:CustomsRegimePlaceholder')}:
          </div>
          <div className="overall-item__value">{formattedCustomsMode}</div>
        </div>
        <div className="overall-item">
          <div className="overall-item__title">
            {t('CustomsPayments:ContractCurrencyPlaceholder')}:
          </div>
          <div className="overall-item__value">{contractCurrency.label}</div>
        </div>
      </div>

      <div className="overall-col">
        <div className="overall-item">
          <div className="overall-item__title">{t('CustomsPayments:BorderShippingCosts')}:</div>
          <div className="overall-item__value">
            {getFormattedPrice(fieldValues[borderDeliverySumField.code], 2)}{' '}
            {borderDeliveryCurrency.label}
          </div>
        </div>
        <div className="overall-item">
          <div className="overall-item__title">{t('CustomsPayments:OtherExpenses')}:</div>
          <div className="overall-item__value">
            {getFormattedPrice(fieldValues[otherExpensesSumField.code], 2)}{' '}
            {otherExpensesCurrency.label}
          </div>
        </div>
      </div>
    </div>
  );
}

export { CustomsPaymentsDetailingOverall };
