import { VatItem } from '@services/requests/customsTaxService/interface';

import { moneyRounding } from './price';

interface GetVatCalcProps {
  selectedVat: VatItem;
  feeRowData: Calculation;
  antidumpingFeeRowData: Calculation;
  exciseRowData: Calculation;
  title: string;
  cost: number;
}

interface Calculation {
  id: string;
  name: string;
  rate: string;
  rublesAmount: string;
}

function getVatCalc({
  selectedVat,
  feeRowData,
  antidumpingFeeRowData,
  exciseRowData,
  title,
  cost,
}: GetVatCalcProps) {
  if (!selectedVat || selectedVat?.Value === '0') {
    return {
      id: 'vat',
      name: title,
      rate: '0%',
      rublesAmount: '0',
    };
  }

  const exciseSum = +exciseRowData?.rublesAmount || 0;
  const feeSum = +feeRowData.rublesAmount;
  const antidumpingFeeSum = +antidumpingFeeRowData?.rublesAmount || 0;
  const vatBase = +selectedVat.ValueDetail.ValueCount;

  const vatSum = moneyRounding(
    moneyRounding(
      moneyRounding(moneyRounding(cost + moneyRounding(feeSum + antidumpingFeeSum)) + exciseSum) *
        vatBase
    ) / 100
  );

  return {
    id: 'vat',
    name: title,
    rate: selectedVat.Value.split('|')[0],
    rublesAmount: vatSum.toString(),
  };
}

export { getVatCalc };
