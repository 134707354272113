import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentsTemplateProduct } from '../types';
import { CustomsPaymentsDetailingProductHelpItem } from './CustomsPaymentsDetailingProductHelpItem';

interface CustomsPaymentsDetailingProductHelpProps {
  products: CustomsPaymentsTemplateProduct[];
}

function CustomsPaymentsDetailingProductHelp({
  products,
}: CustomsPaymentsDetailingProductHelpProps) {
  const { t } = useTranslation(['CustomsPayments']);

  return (
    <div className="product-help-detailing">
      {products.map((product, index) => (
        <React.Fragment key={'product-help-detailing-' + index}>
          <div className="title">
            {t('CustomsPayments:ProductHelpTitle')} #{index + 1}
          </div>
          {product.notes.map((note, noteIndex) => (
            <CustomsPaymentsDetailingProductHelpItem
              key={'product-help-detailing-item-' + noteIndex}
              note={note}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

export { CustomsPaymentsDetailingProductHelp };
