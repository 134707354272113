import { customsTaxService } from '@services/requests/customsTaxService';
import { CountriesResponse } from '@services/requests/customsTaxService/interface';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ServiceProps } from '../types';
import { CustomsPaymentsDetailingMain } from './CustomsPaymentsDetailing/CustomsPaymentsDetailingMain';
import { CustomsPaymentsDetailingOverall } from './CustomsPaymentsDetailing/CustomsPaymentsDetailingOverall';
import { CustomsPaymentsDetailingProductHelp } from './CustomsPaymentsDetailing/CustomsPaymentsDetailingProductHelp';
import { CustomsPaymentsDetailingResult } from './CustomsPaymentsDetailing/CustomsPaymentsDetailingResult';
import { CustomsPaymentsDetailingStyledContainer } from './CustomsPaymentsDetailing/StyledComponents';
import { CustomsPaymentsProductList } from './CustomsPaymentsProductList';
import { CustomsPaymentsTemplateStore } from './types';

const CustomsPaymentsTemplate: FC<ServiceProps> = (props) => {
  const { service, serviceTemplateStore, isOrderDisplay } = props;

  const [customsNotesContainer, setCustomsNotesContainer] = useState<HTMLDivElement>(null);
  const [countries, setCountries] = useState<CountriesResponse>([]);

  useEffect(() => {
    customsTaxService().LoadCountries().then(setCountries);
  }, []);

  useEffect(() => {
    const containerNode = document.querySelector(
      '#customs-payments-notes-container'
    ) as HTMLDivElement;

    if (containerNode) {
      setCustomsNotesContainer(containerNode);
    }
  }, []);

  const store: CustomsPaymentsTemplateStore = serviceTemplateStore[service.id];
  const isLastProduct = store.products.length === 1;

  if (isOrderDisplay) {
    return (
      <CustomsPaymentsDetailingStyledContainer>
        <div className="customs-payments-wrap">
          <CustomsPaymentsDetailingOverall {...props} />
          {store.products.map((product, index) => (
            <div key={'product-detailing-' + index}>
              <CustomsPaymentsDetailingMain
                product={product}
                index={index}
                countries={countries}
                {...props}
              />
              <CustomsPaymentsDetailingResult product={product} {...props} />
            </div>
          ))}
        </div>
        {customsNotesContainer &&
          createPortal(
            <CustomsPaymentsDetailingProductHelp products={store.products} />,
            customsNotesContainer
          )}
      </CustomsPaymentsDetailingStyledContainer>
    );
  }

  return (
    <CustomsPaymentsProductList
      isLastProduct={isLastProduct}
      products={store.products}
      countries={countries}
      {...props}
    />
  );
};

export default CustomsPaymentsTemplate;
