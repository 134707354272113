import LocationOrTerminalPoint from '@components/pages/LocationOrTerminalPoint';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import { Typography } from '@material-ui/core';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { Order } from '@services/requests/orderService/interface';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  branchItem: BranchItem;
  order: Order;
  totalPrice?: number;
  currencyCode?: string;
}

const TerminalsInfoGroupTitle: FC<Props> = ({
  id,
  branchItem,
  currencyCode,
  totalPrice,
  order,
}) => {
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['Common'], { lng: langCode });
  return (
    <Typography variant={'subtitle2'} component={'div'} className={'offer-footer-info__heading'}>
      <div>
        <LocationOrTerminalPoint
          value={{ id: id, type: 'terminal' }}
          branchItem={branchItem}
          order={order}
        >
          {(_primary, secondary, _symbolCode) => {
            const [location, ...parents] = secondary;

            return (
              <>
                {location} ({t('Common:Terminal')}), {parents.join(', ')}
              </>
            );
          }}
        </LocationOrTerminalPoint>
      </div>
      {totalPrice && currencyCode && (
        <div>
          {formatPrice(totalPrice)} {currencyCode}
        </div>
      )}
    </Typography>
  );
};

export default TerminalsInfoGroupTitle;
