import { RouteProductContext } from '@components/pages/Products/components/RouteProduct';
import { TaxNameProps } from '@helpers/getTaxNames';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { Order } from '@services/requests/orderService/interface';
import {
  ContainerParameters,
  CustomCargoParameters,
  RouteInGroup,
} from '@services/requests/orderService/types';
import React from 'react';

import ProductRouteItem from './ProductRouteItem';

/**
 * Пропсы компонента
 */
type OfferItemProps = {
  order: Order;
  cargo: string;
  route: RouteInGroup;
  taxNames: TaxNameProps[];
  containerParameters: ContainerParameters[];
  customCargoParameters: CustomCargoParameters[];
  realTimeBaseData: RouteWidgetBaseParameters;
};

/**
 * Компонент вывода информации об одном маршруте
 * @param props
 */
const OfferRouteGroupItem = (props: OfferItemProps) => {
  const { realTimeBaseData } = props;

  return (
    <RouteProductContext.Consumer>
      {(routeBaseData) => {
        if (!routeBaseData) {
          return null;
        }

        return (
          <ProductRouteItem {...props} {...routeBaseData} realTimeBaseData={realTimeBaseData} />
        );
      }}
    </RouteProductContext.Consumer>
  );
};

export default OfferRouteGroupItem;
