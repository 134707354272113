import { getLocalizationById } from '@context/useMyRouting/helpers';
import { Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import TransportTypeIcon from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/TransportTypeIcon';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { Order } from '@services/requests/orderService/interface';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  fullPrice: number;
  currencyCode: string;
  order: Order;
}

const TerminalsInfoHeader: FC<Props> = ({ fullPrice, currencyCode, order }) => {
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail'], { lng: langCode });
  return (
    <div className={'offer-group-header'}>
      <Box className="offer-group-header__title">
        <TransportTypeIcon transportType={`terminal`} />
        <Typography variant={'subtitle1'} component={'div'}>
          {t('OfferDetail:TerminalCosts')}
        </Typography>
      </Box>
      <Typography variant={'subtitle1'} className={'price'} component={'div'} gridColumn={'span 2'}>
        {fullPrice > 0 && `${formatPrice(fullPrice)} ${currencyCode}`}
      </Typography>
    </div>
  );
};

export default TerminalsInfoHeader;
