import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Найденная сущность
 */
export interface SearchEntityItem {
  entityId: string;
  entityType: string;
}

/**
 * Источник поиска
 */
export type SearchSource = 'additional_services' | 'transport';

/**
 * Результат выполнения поискового запроса
 */
export interface LocationsAndTerminalSearchQueryResult {
  searchLocationsAndTerminals: SearchEntityItem[];
}

/**
 * Поисковый запрос для поиска локаций и терминалов
 */
export class LocationsAndTerminalSearchQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(searchString: string, searchSource: SearchSource) {
    this.variables = null;
    this.query = gql`
            query SearchLocationsAndTerminals {
              searchLocationsAndTerminals(searchPhrase: "${searchString.replace(
                `"`,
                `\"`
              )}", searchSource: ${searchSource}) {
                entityId
                entityType
              }
            }
        `;
  }
}
