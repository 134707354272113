import { $error } from '@settings/errorContext';
import { Logger, LoggerFactory } from '@settings/services/logger/Logger';

import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';
import { SearchSource } from '../locationsAndTerminalSearchService/LocationsAndTerminalSearchQuery';
import {
  LocationsAndTerminalSearchServiceInterface,
  SearchResult,
} from '../locationsAndTerminalSearchService/types';
import { TerminalByLocationLoaderInterface } from '../TerminalByLocationLoader/interface';
import {
  BranchItem,
  LocationsBranchServiceInterface,
  _LocationsBranchServiceProcessorInterface,
} from './interfaces';

/**
 * Сервис поиска веток локаций по поисковому запросу
 */
export class LocationsBranchService implements LocationsBranchServiceInterface {
  private readonly locationsAndTerminalSearchService: LocationsAndTerminalSearchServiceInterface;

  private readonly processors: _LocationsBranchServiceProcessorInterface[];

  private readonly terminalByLocationLoader: TerminalByLocationLoaderInterface;

  private readonly logger: Logger;

  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  /**
   * Конструктор сервиса
   * @param locationsAndTerminalSearchService
   * @param localizationLoader
   * @param terminalByLocationLoader
   * @param processors
   * @param logger
   */
  constructor(
    locationsAndTerminalSearchService: LocationsAndTerminalSearchServiceInterface,
    logger: LoggerFactory,
    localizationLoader: LocalizedMessagesServiceInterfaces,
    terminalByLocationLoader: TerminalByLocationLoaderInterface,
    ...processors: _LocationsBranchServiceProcessorInterface[]
  ) {
    this.terminalByLocationLoader = terminalByLocationLoader;
    this.locationsAndTerminalSearchService = locationsAndTerminalSearchService;
    this.processors = processors;
    this.localizationLoader = localizationLoader;
    this.logger = logger.make(`LocationsBranchService`);
  }

  /**
   * Поиск веток по поисковой строке
   */
  async SearchBranches(searchString: string, searchSource: SearchSource): Promise<BranchItem[]> {
    const baseItems = await this.locationsAndTerminalSearchService.SearchLocationsAndTerminals(
      searchString,
      searchSource
    );
    this.logger.Debug(`SearchBranches() -> Loaded base items`, baseItems);

    const groupedBranches: { [T in 'location' | 'terminal']: SearchResult[] } = {
      location: [],
      terminal: [],
    };

    baseItems.map((i) => groupedBranches[i.type].push(i));

    const items = await this.loadLocations(groupedBranches);

    this.logger.Debug(`SearchBranches() -> Loaded base branches`, items);

    return this.getLocationWithParentTree(baseItems, await this.loadLocalization(items));
  }

  /**
   * Получение списка родительских локаций по переданным спискам ID терминалов и локаций
   * @param terminalId
   * @param locationsId
   */
  async getParentLocationById(terminalId: string[], locationsId: string[]): Promise<BranchItem[]> {
    const groupedBranches: { [T in 'location' | 'terminal']: SearchResult[] } = {
      location: locationsId.map((id) => ({
        id,
        type: 'terminal',
      })),
      terminal: terminalId.map((id) => ({
        id,
        type: 'terminal',
      })),
    };

    const items = await this.loadLocations(groupedBranches);

    return this.getLocationWithParentTree([], await this.loadLocalization(items));
  }

  /**
   *
   * @param location
   */
  async getChildrenLocations(location: BranchItem): Promise<BranchItem[]> {
    let currentLocation = location;

    if (location.type === 'terminal') {
      const items = await this.getBranchByProcessor('terminal', [
        {
          id: location.id as string,
          type: 'terminal',
        },
      ]);

      currentLocation = items.find(
        (l) => l.id === location.parentId && l.type === 'location'
      ) as BranchItem;
    }
    const childrenLocation = await this.locationsAndTerminalSearchService.GetChildrenForLocation(
      currentLocation.id
    );

    childrenLocation.push({
      id: currentLocation.id,
      type: 'location',
    });

    const items = await this.terminalByLocationLoader.Load(childrenLocation.map((l) => l.id));

    const baseItems = items.map<SearchResult>((t) => ({
      type: t.type,
      id: t.id,
    }));

    baseItems.push({
      id: currentLocation.id,
      type: 'location',
    });

    return this.getLocationWithParentTree(baseItems, await this.loadLocalization(items));
  }

  /**
   * Получение значения по переданному ID и типу сущности
   * @param id
   * @param type
   */
  async GetItemByValue(id: string, type: 'location' | 'terminal'): Promise<BranchItem> {
    const groupedBranches: { [T in 'location' | 'terminal']: SearchResult[] } = {
      location: [],
      terminal: [],
    };

    groupedBranches[type].push({ id, type });

    const item = (await this.getItemsByGroups(groupedBranches)).find(
      (i) => i.id === id && i.type === type
    );
    if (!item) {
      $error.next(`Failed to get value by passed parameters`);
    }

    const result = (await this.loadLocalization([item])).find(
      (i) => i.id === id && i.type === type
    );
    if (!result) {
      $error.next(`Failed to get localization by passed parameters`);
    }

    return result;
  }

  /**
   * Получение значения по переданному ID и типу сущности
   * @param id
   * @param type
   */
  async GetItemsByValue(id: string[], type: 'location' | 'terminal'): Promise<BranchItem[]> {
    const groupedBranches: { [T in 'location' | 'terminal']: SearchResult[] } = {
      location: [],
      terminal: [],
    };

    groupedBranches[type] = id.map((id) => ({
      id,
      type,
    }));

    return await this.loadLocalization(await this.getItemsByGroups(groupedBranches));
  }

  /**
   * Получение списка локаций и терминалов
   * @param groupedBranches
   * @private
   */
  private async loadLocations(groupedBranches: {
    [T in 'location' | 'terminal']: SearchResult[];
  }): Promise<BranchItem[]> {
    const baseBranchItems = await Promise.all(
      (Object.keys(groupedBranches) as ('location' | 'terminal')[]).map((key) =>
        this.getBranchByProcessor(key, groupedBranches[key])
      )
    );

    this.logger.Debug(`SearchBranches() -> Loaded base branches`, baseBranchItems);

    // Объединяем все ветки в один большой массив
    const items: BranchItem[] = [];

    baseBranchItems.map((branchItems) => {
      branchItems.map((i) => {
        const existsItem = items.find((ii) => ii.id === i.id && ii.type === i.type);
        if (existsItem === undefined) {
          items.push(i);
        }
      });
    });

    return items;
  }

  /**
   * Получение ветки для базового элемента при помощи процессоров
   * @param type
   * @param items
   */
  private async getBranchByProcessor(
    type: 'location' | 'terminal',
    items: SearchResult[]
  ): Promise<BranchItem[]> {
    if (items.length === 0) {
      return [];
    }

    const processor = this.processors.find((p) => p.isAvailable(type));
    if (processor === undefined) {
      this.logger.Error(`getBranchByProcessor() -> Noone processor is found for type`, type);
      return [];
    }

    return await processor.GetBranch(items);
  }

  /**
   * Формирование локаций с их родителями
   * @param baseItems
   * @param locations
   * @private
   *
   * Теперь пробуем собрать все элементы без родителя в итоговый результат
   * элементы с родителями обрабатываем, добавляем их родителей.
   * Так как объекты в JS передаются по ссылке, то по сути в итоговом результате
   * получим корректные ветки с заполненными дочерними элементами, причем, они будут
   * выстраниваться по релевантности, относительно главных родительских элементов
   */
  getLocationWithParentTree(baseItems: SearchResult[], locations: BranchItem[]): BranchItem[] {
    const branches: BranchItem[] = [];

    baseItems.map((searchItem) => {
      const branchItem = locations.find(
        (l) => l.id === searchItem.id && l.type === searchItem.type
      );
      if (!branchItem) {
        return;
      }

      let { parentId } = branchItem;

      while (parentId) {
        // решаем проблему с идентичными ID для терминалов и локаций
        const parentItem = locations.find((j) => j.id === parentId && j.type === 'location');

        if (!parentItem) {
          break;
        }

        branchItem.subItems.push({
          iso: parentItem.iso,
          id: parentItem.id,
          type: parentItem.type,
          visibleName: parentItem.visibleName,
          parentId: parentItem.parentId,
          depthLevel: parentItem.depthLevel,
          symbolCode: parentItem.symbolCode,
          localizedNames: parentItem.localizedNames,
          localizedNamesArray: parentItem.localizedNamesArray,
          localizedIsoArray: parentItem.localizedIsoArray,
          files: parentItem.files,
        });
        parentId = parentItem?.parentId;
      }

      branches.push(branchItem);
    });

    return branches;
  }

  /**
   * Получение списка локализаций для каждой локации
   * @private
   */
  async loadLocalization(locations: BranchItem[]): Promise<BranchItem[]> {
    // Формируем общий список локализаций (ID)
    const localizedMessageIds = locations.reduce(
      (result: string[], item: BranchItem): string[] => [
        ...result,
        ...item.localizedNames,
        ...item.localizedIso,
        ...item.subItems.map((s) => [...s.localizedNames]).flat(1),
      ],
      []
    );

    // Загружаем все локализованные тексты
    const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);

    locations.map((item) => {
      item.localizedNamesArray = messages.filter((m) => item.localizedNames.indexOf(m.id) !== -1);
      item.localizedIsoArray = messages.filter((m) => item.localizedIso.indexOf(m.id) !== -1);
      item.subItems = item.subItems.map((s) => ({
        ...s,
        localizedNamesArray: messages.filter((m) => s.localizedNames.includes(m.id)),
      }));
    });

    return locations;
  }

  /**
   * Получение веток для переданных групп значений поиска
   * @param groupedBranches
   */
  private async getItemsByGroups(groupedBranches: {
    [T in 'location' | 'terminal']: SearchResult[];
  }): Promise<BranchItem[]> {
    const baseBranchItems = await Promise.all(
      (Object.keys(groupedBranches) as ('location' | 'terminal')[]).map((key) =>
        this.getBranchByProcessor(key, groupedBranches[key])
      )
    );

    this.logger.Debug(`SearchBranches() -> Loaded base branches`, baseBranchItems);

    // Объединяем все ветки в один большой массив
    const items: BranchItem[] = [];
    baseBranchItems.map((branchItems) => {
      branchItems.map((i) => {
        // исключаем дублирование
        const existsItem = items.find((ii) => ii.id === i.id && ii.type === i.type);
        if (existsItem === undefined) {
          items.push(i);
        }
      });
    });

    // Теперь пробуем собрать все элементы без родителя в итоговый результат
    // Элементы с родителями обрабатываем, добавляем их в родителей.
    // Так как объекты в JS передаются по ссылке, то по сути в итоговом результате
    // получим корректные ветки с заполненными дочерними элементами, причем, они будут
    // выстраиваться по релевантности, относительно главных родительских элементов
    const branches: BranchItem[] = [];

    items.map((item) => {
      let { parentId } = item;

      while (parentId) {
        // решаем проблему с идентичными ID для терминалов и локаций
        const parentItem = items.find((j) => j.id === parentId && j.type === 'location');

        item.subItems.push({
          iso: parentItem.iso,
          id: parentItem.id,
          type: parentItem.type,
          visibleName: parentItem.visibleName,
          parentId: parentItem.parentId,
          symbolCode: parentItem.symbolCode,
          depthLevel: parentItem.depthLevel,
          localizedNames: parentItem.localizedNames,
          localizedNamesArray: parentItem.localizedNamesArray,
          localizedIsoArray: parentItem.localizedIsoArray,
          files: parentItem.files,
        });
        parentId = parentItem?.parentId;
      }

      branches.push(item);
    });

    return branches;
  }
}
