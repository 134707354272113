import palette from '@assets/theme/palette';
import { Link, Typography } from '@material-ui/core';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const VideoLink = styled(Link)`
  color: ${palette.light.blue[400]};
  border-bottom: 1px solid transparent;
  transition: 400ms;

  &:hover {
    color: #224179;
    border-bottom: 1px solid ${palette.light.blue[400]};
  }
`;

const VideoInstruction: FC = () => {
  const { t } = useTranslation(['CustomsCalculator', 'Common']);

  return (
    <Typography variant={'body1'} component={'div'} className={'video-instruction'}>
      <VideoLink
        color={palette.light.blue[400]}
        href={t('Common:InstructionLink')}
        target="_blank"
        underline={'none'}
      >
        {t('CustomsCalculator:VideoInstruction')}
      </VideoLink>
    </Typography>
  );
};

export default VideoInstruction;
