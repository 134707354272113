import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

import { moneyRounding } from './price';

interface GetBaseCalcProps extends GetBaseCalcLocalizedLabels {
  contractCurrency: CurrencyData;

  productContractCurrencyCost: number;

  borderDeliveryCurrency: CurrencyData;
  borderDeliveryCost: number;

  otherExpensesCurrency: CurrencyData;
  otherExpensesCost: number;

  totalContractCurrencyCost: number;
  totalProductsWeight: number;
  productWeight: number;
  productCost: number;
}

interface GetBaseCalcLocalizedLabels {
  contractCurrencyLabel: string;
  borderDeliveryLabel: string;
  otherExpensesLabel: string;
  baseTotalLabel: string;
}

function getBaseCalc({
  productContractCurrencyCost,
  contractCurrency,

  borderDeliveryCost,
  borderDeliveryCurrency,
  productWeight,

  otherExpensesCost,
  otherExpensesCurrency,
  productCost,

  totalContractCurrencyCost,
  totalProductsWeight,

  contractCurrencyLabel,
  borderDeliveryLabel,
  otherExpensesLabel,
  baseTotalLabel,
}: GetBaseCalcProps) {
  const contractRowData = {
    id: 'contract',
    name: contractCurrencyLabel,
    currencyAmount: productContractCurrencyCost,
    currency: contractCurrency.code,
    rublesAmount: moneyRounding(productContractCurrencyCost * contractCurrency.course),
  };

  const weightPercentage =
    totalProductsWeight !== 0 && productWeight !== 0
      ? 100 / (totalProductsWeight / productWeight)
      : 0;
  const weightCurrencyCost = moneyRounding(
    moneyRounding(borderDeliveryCost / 100) * weightPercentage
  );

  const borderDeliveryRowData = {
    id: 'borderDelivery',
    name: borderDeliveryLabel,
    currencyAmount: weightCurrencyCost,
    currency: borderDeliveryCurrency.code,
    rublesAmount: moneyRounding(weightCurrencyCost * borderDeliveryCurrency.course),
  };

  const costPercentage =
    totalContractCurrencyCost !== 0 && productCost !== 0
      ? 100 / (totalContractCurrencyCost / productCost)
      : 0;
  const costCurrencyCost = moneyRounding(moneyRounding(otherExpensesCost / 100) * costPercentage);

  const otherExpensesRowData = {
    id: 'otherExpenses',
    name: otherExpensesLabel,
    currencyAmount: costCurrencyCost,
    currency: otherExpensesCurrency.code,
    rublesAmount: moneyRounding(costCurrencyCost * otherExpensesCurrency.course),
  };

  const totalRowData = {
    id: 'baseTotal',
    name: baseTotalLabel,
    currencyAmount: 0,
    currency: contractCurrency.code,
    rublesAmount: moneyRounding(
      contractRowData.rublesAmount +
        borderDeliveryRowData.rublesAmount +
        otherExpensesRowData.rublesAmount
    ),
  };

  return [contractRowData, borderDeliveryRowData, otherExpensesRowData, totalRowData];
}

export { getBaseCalc };
