import { getLocalizationById } from '@context/useMyRouting/helpers';
import { Typography } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { Order } from '@services/requests/orderService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface OfferTipsTextProps {
  order: Order;
}

/**
 * Компонент вывода подсказок по заказу
 */
const OfferTipsText = ({ order }: OfferTipsTextProps) => {
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation('OfferTipsText', { lng: langCode });
  const theme = useTheme();
  return (
    <>
      <Typography variant={'body2'} color={theme.palette.grey[300]} fontSize={13}>
        {t('OfferTipsText:Part1')}
      </Typography>
      <Typography variant={'body2'} color={theme.palette.grey[300]} fontSize={13}>
        {t('OfferTipsText:Part2')}
      </Typography>
    </>
  );
};

export default OfferTipsText;
