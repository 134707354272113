import { useYaHit } from '@context/useYaHit';
import useWidgetTabsContext from '@pages/AdditionalServices/containers/widgetTabsContext';
import AdditionalForm from '@pages/AdditionalServices/tabs/AdditionalForm';
import CustomsPayments from '@pages/AdditionalServices/tabs/CustomsPayments';
import DeliveryForm from '@pages/AdditionalServices/tabs/DeliveryForm';
import React from 'react';

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const FormWidget = () => {
  const { tabs } = useWidgetTabsContext();
  const activeTab = tabs.ActiveTabData.type === 'service' ? 2 : tabs.ActiveTab;

  useYaHit({});

  return (
    <div id={'my-global-portal-container'}>
      <CustomTabPanel value={activeTab} index={0}>
        <DeliveryForm />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={2}>
        <AdditionalForm />
      </CustomTabPanel>
    </div>
  );
};

export default FormWidget;
