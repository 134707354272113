import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RouteInformationProps {
  routeInformations: string[];
  isContentVisible: boolean;
  isDetailingOutput?: boolean;
  onClickHeader?: () => void;
}

/**
 * Компонент вывода списка справок о маршруте
 */
function RouteInformation({
  routeInformations,
  isDetailingOutput,
  isContentVisible,
  onClickHeader,
}: RouteInformationProps) {
  const { t } = useTranslation('RoutesListComponent');

  return (
    <div className={clsx('route-info-wrap', { detailing: isDetailingOutput })}>
      <span
        onClick={() => onClickHeader && onClickHeader()}
        className={clsx('route-info-title', {
          'content-visible': isContentVisible,
          clickable: !!onClickHeader,
        })}
      >
        {t('RoutesListComponent:ImportantInformation')}
      </span>
      {isContentVisible &&
        routeInformations.map((info, index) => (
          <div
            key={'route-information-' + index}
            className="route-infor-text"
            dangerouslySetInnerHTML={{ __html: info }}
          ></div>
        ))}
    </div>
  );
}

export { RouteInformation };
