import { moneyRounding } from './price';

export function getCalculation(
  count: number,
  unit: string,
  currencyUnit: string,
  currencyRate: number,
  price: number,
  base: number
) {
  let res = 0;

  if (unit === '%') {
    res = moneyRounding(moneyRounding(count * price) * 0.01);
  } else if (currencyUnit !== 'руб') {
    res = moneyRounding(moneyRounding(count * currencyRate) * base);
  } else {
    res = moneyRounding(count * base);
  }

  return res;
}
