import OfferRouteGroupItem from '@components/pages/Products/components/RouteProduct/components/OfferRouteGroupItem';
import { getCargoDescription } from '@components/pages/Products/components/RouteProduct/helpres';
import { ProductComponentProps } from '@components/pages/Products/types';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@mui/material/Typography';
import {
  calculateCargoForContainers,
  calculateCargoForCustom,
} from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/helpers';
import { getCartPriceSummary } from '@pages/Basket/helpers';
import OfferTipsText from '@pages/OfferDetail/components/ProductList/components/OfferTipsText';
import { getRouteCodes, getValidityCalcDate } from '@pages/OfferDetail/helpers';
import { RouteResult } from '@services/requests/orderService/types';
import React, { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RouteSummary from 'src/components/pages/Products/components/RouteProduct/components/RouteSummary';

import { RouteProductContextData } from './types';

/*
Контекст с базовыми данными, которые грузятся динамически и не привязаны к продуктам
 */
export const RouteProductContext = createContext<RouteProductContextData | undefined>(undefined);

const RouteProduct = (props: ProductComponentProps) => {
  const { product, order, realTimeBaseData, taxNames } = props;
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail', 'CartSummary'], { lng: langCode });

  const summary = useMemo(() => {
    const orderOnlyThisProduct = {
      ...order,
      products: [product],
    };
    return getCartPriceSummary(orderOnlyThisProduct);
  }, [order]);

  const productData: RouteResult = useMemo(() => JSON.parse(product?.data || '{}'), [product]);

  // считаем коды, даже если грузов будет несколько, то маршруты у них будут идентичные
  const { offersCode, shouldersCode } = getRouteCodes(productData?.route?.routes[0].route);

  const validDateText = useMemo(() => getValidityCalcDate([product], langCode), [order.products]);

  const currencyRoute = useMemo(
    () => productData.baseData?.currencies.find((c) => c.id === order.currency_id),
    [productData, order]
  );

  const { cargoParameters } = productData;

  // Получаем данные маршрута
  const route: RouteResult = JSON.parse(product.data);

  // Получаем описание типа груза
  const cargo =
    route.type === 'container'
      ? calculateCargoForContainers(
          realTimeBaseData.containers,
          route.cargoParameters.containerParameters,
          langCode
        ).join('; ')
      : calculateCargoForCustom(
          realTimeBaseData.units,
          route.cargoParameters.customCargoParameters,
          langCode
        ).join('; ');

  if (!product || !realTimeBaseData || !currencyRoute) {
    return null;
  }

  const cargoArray = cargo.split(';');

  return (
    <RouteProductContext.Provider
      value={{
        currencyRoute,
        product,
        productData,
        realTimeBaseData,
        primaryLanguageId: '1',
        secondaryLanguageId: '2',
      }}
    >
      <RouteSummary
        {...summary}
        cargo={cargoArray}
        order={order}
        product={product}
        orderCurrency={order.currency_id}
        langId={order.language_id}
        products={[]}
      />
      <Typography variant={'subtitle2'} component={'div'} className="add-info">
        {t('OfferDetail:RatesValid')} {validDateText}
        <br />
        {t('OfferDetail:OfferID')}: {offersCode}, {t('OfferDetail:ShoulderID')}: {shouldersCode}{' '}
      </Typography>
      {route.isNotCompleteResult && (
        <div className="route-notes">
          <div className="route-is-not-full">
            <ErrorOutlineIcon fontSize={'small'} />
            <div>{t('RoutesListComponent:isRouteNotCompleteName')}</div>
            <div>{t('RoutesListComponent:isRouteNotComplete')}</div>
          </div>
        </div>
      )}
      <div>
        <OfferTipsText order={order} />
      </div>
      {productData?.route?.routes.map((routeGroup) => {
        const containersInfo = getCargoDescription(realTimeBaseData, route, routeGroup, langCode);

        return (
          <OfferRouteGroupItem
            order={order}
            cargo={containersInfo}
            key={`route-group-${routeGroup.route.routeId}`}
            route={routeGroup}
            taxNames={taxNames}
            containerParameters={cargoParameters.containerParameters || []}
            customCargoParameters={cargoParameters.customCargoParameters || []}
            realTimeBaseData={realTimeBaseData}
          />
        );
      })}
    </RouteProductContext.Provider>
  );
};

export default RouteProduct;
