import clsx from 'clsx';
import React, { FC } from 'react';

interface BaseTotalTableProps {
  data: {
    id: string;
    name: string;
    currencyAmount: number;
    currency: string;
    rublesAmount: number;
  }[];
}

const BaseTotalTable: FC<BaseTotalTableProps> = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <React.Fragment key={`${item.id}-${index}`}>
          <div
            className={clsx('no-left-border', {
              'customs-payments-total': data.length - 1 === index,
            })}
          >
            {item.name}
          </div>
          {data.length - 1 !== index && (
            <div className="center">
              {item.currencyAmount} {item.currency}
            </div>
          )}
          <div className="center">{item.rublesAmount} RUB</div>
        </React.Fragment>
      ))}
    </>
  );
};

export default BaseTotalTable;
