import useBaseDataContext from '@context/useBaseDataContext';
import getServicesBySection, { getCustomsPaymentsServices } from '@helpers/getServicesBySection';
import Typography from '@mui/material/Typography';
import { getSectionServiceName } from '@pages/Basket/components/ServicesList/helpers';
import ServicesListStyledContainer from '@pages/Basket/components/ServicesList/StyledComponent';
import { Order } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderList from 'src/pages/Basket/components/ServicesList/components/OrderList';

interface ServicesListWrapperProps {
  order: Order;
}

const ServicesListWrapper: FC<ServicesListWrapperProps> = ({ order }) => {
  const { useBaseData } = useBaseDataContext();
  const [baseData] = useBaseData().useState();
  const { currencies } = baseData;
  const { t } = useTranslation(['CartComponent', 'CustomsPayments']);

  const [productBySection, setProductBySection] = useState([]);

  useEffect(() => {
    const routes: OrderProduct[] = [];
    const services: OrderProduct[] = [];
    order.products.forEach((p) => {
      switch (p.product_type) {
        case 'route':
          routes.push(p);
          break;
        case 'service':
        case 'complex':
          services.push(p);
          break;
      }
    });
    setProductBySection([
      routes,
      ...getServicesBySection(services).map((s) => s.services),
      ...[getCustomsPaymentsServices(services)],
    ]);
  }, [order]);

  return (
    <ServicesListStyledContainer>
      {productBySection.map(
        (section, index) =>
          section.length > 0 && (
            <div key={`order-type-${index}`} className={'section'}>
              <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
                {getSectionServiceName(section, t, order.language_id)}
              </Typography>
              <OrderList
                products={section}
                currencyCode={
                  currencies ? currencies.find((c) => c.id === order.currency_id).code : ''
                }
              />
            </div>
          )
      )}
    </ServicesListStyledContainer>
  );
};

export default ServicesListWrapper;
