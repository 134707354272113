import getEnv from "@settings/getEnv";
import {checkEnvAvailability} from "@settings/getEnv/helpers";
import {initOptions} from "@pages/AdditionalServices/containers/additionServicesOptionsContext";
import {directoryLoadingSubscriber} from "@pages/AdditionalServices/containers/subscribers/directoryLoadingSubscriber";
import {bufferTime} from "rxjs";
import {
  isLastLevelSelectedCheckSubscriber
} from "@pages/AdditionalServices/containers/subscribers/isLastLevelSelectedCheckSubscriber";
import {
  makeDirectoryFieldsSubscriber
} from "@pages/AdditionalServices/containers/subscribers/makeDirectoryFieldsSubscriber";
import {getTabsPipe, onChangeTab, pushTabs} from "@pages/AdditionalServices/containers/widgetTabsContext";
import {ServiceDirectorySettings} from "@onlog-public/additional-services-types";
import {serviceLoadingSubscriber} from "@pages/AdditionalServices/containers/subscribers/serviceLoadingSubscriber";
import {
  calculateServicesSubscriber
} from "@pages/AdditionalServices/containers/subscribers/calculateServicesSubscriber";
import {servicesDirectoriesService} from "@services/requests/servicesService/servicesDirectoriesService";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import {
  serviceCalculationBus$,
  serviceContext$,
  serviceLoadingBus$,
} from "@pages/AdditionalServices/containers/bus";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {resetSelectedDirectory} from "@pages/AdditionalServices/containers/methods/resetSelectedDirectory";
import {setSelectedDirectory} from "@pages/AdditionalServices/containers/methods/setSelectedDirectory";
import makeDirectoryPropertiesSubscriber
  from "@pages/AdditionalServices/containers/subscribers/makeDirectoryPropertiesSubscriber";
import initPropertyValueOptions from "@pages/AdditionalServices/containers/methods/initPropertyValueOptions";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * getLocalStorageDataToStoreInCache возвращает данные, которые будут сохраняться в кэш LocalStorage
 * и извлекаться при восстановлении стейта. Данные, помещаемые сюда должны актуализироваться в
 * процессе работы стейта. Данные используются только для первоначального открытия страницы.
 *
 * @param state
 */
const getLocalStorageDataToStoreInCache = (state: AdditionServicesState) => {
  const {RootServices} = state

  return {
    RootServices
  }
}

/**
 * Инициализация данных стейта. Загружает данные, а так же добавляет необходимую логику.
 */
export const initialization = async (langID: string, currencyID: string) => {
  const {REACT_APP_FEATURE_ADDITIONAL_SERVICES_ENABLED} = getEnv();
  const isAdditionalServicesEnabled = checkEnvAvailability(
    REACT_APP_FEATURE_ADDITIONAL_SERVICES_ENABLED
  );

  if (!isAdditionalServicesEnabled) {
    return;
  }

  // Подключаем стейт опций для полей
  initOptions(langID)

  // Подключаем стейт опций для свойств услуг
  initPropertyValueOptions(langID)

  // Подписка автоматически подгружает новые уровни для директорий
  // при изменении выбранных в виджете.
  serviceContext$.pipe().subscribe({
    next: directoryLoadingSubscriber(serviceContext$),
  })

  // Подписчик автоматически вычисляет состояние флага IsLastLevelSelected
  // при изменении списка выбранных директорий.
  serviceContext$.pipe(bufferTime(50)).subscribe({
    next: isLastLevelSelectedCheckSubscriber(serviceContext$),
  })

  // Подписчик вычисляет состояние полей в стейте. Если выбрана крайняя директория,
  // то генерируется список полей для вывода. Для каждого поля инициализируется стейт,
  // а так же инициализируется запрос дополнительных данных.
  serviceContext$.pipe(bufferTime(50)).subscribe({
    next: makeDirectoryFieldsSubscriber(serviceContext$),
  })

  // Подписчик вычисляет все отображаемые свойства выбранных директорий и формирует
  // по ним полноценный стейт значений. Для свойства формирует стейт для хранения
  // его значений.
  serviceContext$.pipe(bufferTime(50)).subscribe({
    next: makeDirectoryPropertiesSubscriber(serviceContext$)
  })

  /**
   * Сохранение данных дополнительных услуг в кэш в localStorage
   */
  serviceContext$.pipe().subscribe({
    next: state => {
      localStorage.setItem(
        '__ONLOG_A_SERVICES_DATA__',
        JSON.stringify(getLocalStorageDataToStoreInCache(state)),
      )
    }
  })

  // Подписка на изменения данных в выбранной вкладке
  getTabsPipe().subscribe({
    next: tabs => {
      if (tabs.ActiveTabData.type !== "service") {
        return resetSelectedDirectory()
      }

      setSelectedDirectory(0, {
        ...tabs.ActiveTabData.data,
        settings: ServiceDirectorySettings.RestoreSettings(tabs.ActiveTabData.data.settings),
      })
    }
  })

  // Подписка на загрузку услуг для выбранных директорий
  serviceLoadingBus$.pipe(bufferTime(100)).subscribe({
    next: serviceLoadingSubscriber(serviceContext$, serviceCalculationBus$)
  })

  // Обработка запросов расчета стоимости услуг. Запускается
  // с перерывами. При расчете используется идентификатор.
  // Значения пишутся только для последнего идентификатора.
  serviceCalculationBus$.pipe(bufferTime(500)).subscribe({
    next: calculateServicesSubscriber(serviceContext$),
  })

  const rootServices = (await servicesDirectoriesService().GetServicesByPath())
    .filter(s => s.is_active)

  const state = clone(serviceContext$.getValue())
  if (Object.keys(state.ServicesPath).length > 0) {
    const selection = rootServices.find(d => d.id === state.ServicesPath['0'].id)
    if (!selection) {
      state.ServicesPath = {}
    }
  }

  serviceContext$.next({
    ...state,
    RootServices: rootServices,
    CurrencyID: currencyID,
    LanguageID: langID,
  })

  pushTabs(rootServices.map(s => ({
    label: getLocalizationByArgs(langID, s.name, s.localized_names),
    data: s,
    type: "service",
  })))

//   serviceContext$.pipe().subscribe({
//     next: state => {
//       if (Object.keys(state.ServicesPath).length === 0) {
//         onChangeTab(0)
//       }
//     }
//   })
}
