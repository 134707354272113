import { Service, ServiceField } from '@onlog-public/additional-services-types';
import { DefaultCustomsPaymentsProduct } from '@pages/AdditionalServices/components/Service/CustomsPaymentsTemplate/DefaultCustomsPaymentsProduct';
import { CustomsPaymentsTemplateStore } from '@pages/AdditionalServices/components/Service/CustomsPaymentsTemplate/types';
import { serviceInitialStateGeneratorProcessor } from '@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/types';

/**
 * CustomsPaymentsTemplateProcessor создает базовый стейт для услуг типа "Таможенные платежи"
 */
export class CustomsPaymentsTemplateProcessor implements serviceInitialStateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для генерации
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === 'customsPayments';
  }

  /**
   * generate выполняет генерацию стейта по полям и услуге. Возвращает сам стейт.
   * @param service
   * @param fields
   * @param values
   */
  generate(_: Service, __: ServiceField[], ___: { [x: string]: number }) {
    const result: CustomsPaymentsTemplateStore = {
      products: [new DefaultCustomsPaymentsProduct()],
    };

    return result;
  }
}
