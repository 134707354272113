import OfferDetailTableHeaderData from '@components/pages/Products/components/RouteProduct/components/ShoulderItemHeaderData';
import ShoulderItemTable from '@components/pages/Products/components/RouteProduct/components/ShoulderItemTable';
import { WithRouteProductContextData } from '@components/pages/Products/components/RouteProduct/types';
import WithRouteProductContext from '@components/pages/Products/components/RouteProduct/WithRouteProductContext';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import { TaxNameProps } from '@helpers/getTaxNames';
import Typography from '@mui/material/Typography';
import { generateLocalizedName } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/helpers';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import ShoulderItemDetailsDTO, {
  RowProps,
} from '@services/collectors/ProductService/ShoulderItemDetailsDTO';
import { Order } from '@services/requests/orderService/interface';
import { RouteStep } from '@services/requests/orderService/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ShoulderItemHeader from './ShoulderItemHeader';
import ShoulderItemStyledContainer from './StyledComponents';

/**
 * Пропсы компонента
 */
type ShoulderItemProps = WithRouteProductContextData<{
  step: RouteStep;
  taxNames: TaxNameProps[];
  order: Order;
}>;

/**
 * Компонент вывода информации о плече и его терминалах
 * @param props
 */
const ShoulderItem = (props: ShoulderItemProps) => {
  const langCode = getLocalizationById(props.order.language_id).countryCode;
  const { t } = useTranslation('OfferDetail', { lng: langCode });

  const { useBaseDate } = useDelivery();
  const [baseData] = useBaseDate().useState();

  const { step, currencyRoute, realTimeBaseData, productData, taxNames, order } = props;
  const {
    shoulderOffer,
    shoulderOfferCalculatedPrice,
    shoulderOfferPriceCondition,
    shoulderAllowances,
    shoulder,
  } = step;

  const column = OfferDetailTableHeaderData(currencyRoute, order);

  const { loadingTransportConditions, unloadingTransportConditions } =
    baseData ?? productData.baseData;

  const startLoadingCondition = step.shoulderOffer.loading_condition_id;
  const endUnloadingCondition = step.shoulderOffer.unloading_condition_id;

  const loadingCondition = loadingTransportConditions.find((c) => c.id === startLoadingCondition);
  const unloadingCondition = unloadingTransportConditions.find(
    (c) => c.id === endUnloadingCondition
  );

  const stepShoulderType = realTimeBaseData.shoulderTypes.find(
    (s) => s.id === step.shoulder.shoulder_type
  );
  const allowancesData = shoulderAllowances.filter((a) => a.allowanceOffer.id !== '1');

  const allowancesDataFiltered: RowProps[] = [];

  allowancesData.forEach((item) => {
    const allowance = realTimeBaseData?.allowancesData.find(
      (ac) => ac.id === item.allowanceOffer.allowance_id
    );
    const title = allowance
      ? generateLocalizedName(
          allowance.default_name,
          allowance.localized_names_data,
          order.language_id
        )
      : '';
    const baseCurrency = productData.baseData?.currencies.find(
      (rc) => rc.id === item.allowanceOfferCondition.currency_id
    );

    if (baseCurrency) {
      allowancesDataFiltered.push({
        price: item.calculatedPrice,
        name: title,
        baseCurrency: baseCurrency || 0,
        targetCurrency: currencyRoute,
        taxName:
          taxNames.find((i) => +i.taxId === item.allowanceOfferCondition.tax_id)?.localizedName ||
          '',
      } as RowProps);
    }
  });

  // Основной перевозчик
  const carrier = useMemo(
    () => realTimeBaseData.carriers.find((c) => c.id === step.shoulder.carrier_id),
    [realTimeBaseData.carriers, step]
  );

  const carrierName =
    (carrier &&
      generateLocalizedName(
        carrier.default_name,
        carrier.localized_names_data,
        order.language_id
      )) ||
    '';
  const { routePointsItems, terminals } = productData;

  const { from: startLocation, to: endLocation } = routePointsItems;

  const isStartFromTerminal = !!step.startTerminal;

  let startPoint = { ...startLocation };
  if (isStartFromTerminal) {
    const terminal = terminals.find((t) => t.id === step.startTerminal.id);
    if (terminal) {
      startPoint = { ...terminal };
    }
  }

  const isEndFromTerminal = !!step.endTerminal;

  let endPoint = { ...endLocation };
  if (isEndFromTerminal) {
    const terminal = terminals.find((t) => t.id === step.endTerminal.id);
    if (terminal) {
      endPoint = { ...terminal };
    }
  }

  const shoulderSteps = shoulder.shoulderSteps.sort((a, b) => (a.position < b.position ? -1 : 1));

  let transportTypes = [`${stepShoulderType?.transport_type}`];
  if (!stepShoulderType?.transport_type) {
    transportTypes = shoulderSteps.map((step) => step.transportType.id);
  }

  const locations = [startPoint];

  if (!stepShoulderType?.transport_type) {
    shoulderSteps.map((step) => {
      if (!step.endTerminal) {
        return;
      }

      const terminal = terminals.find((t) => t.id === step.endTerminal?.id);
      if (!terminal) {
        return;
      }

      locations.push(terminal);
    });
  }

  locations.push(endPoint);

  const totalPrice = shoulderAllowances.reduce(
    (sum, allowance) =>
      sum +
      allowance.calculatedPrice.priceInTargetCurrency +
      allowance.calculatedPrice.taxInTargetCurrency,
    shoulderOfferCalculatedPrice.priceInTargetCurrency +
      shoulderOfferCalculatedPrice.taxInTargetCurrency
  );

  const rowsData = ShoulderItemDetailsDTO([
    {
      baseCurrency: productData.baseData.currencies.find(
        (c) => c.id === shoulderOfferPriceCondition.currency_id
      ),
      price: shoulderOfferCalculatedPrice,
      targetCurrency: currencyRoute,
      taxName:
        taxNames.find((i) => +i.taxId === shoulderOfferPriceCondition.tax_id)?.localizedName || '',
      name: `${t('OfferDetail:Rate')} (${loadingCondition.code} - ${unloadingCondition.code})`,
    },
    ...allowancesDataFiltered,
  ]);

  if (totalPrice === 0) {
    return <></>;
  }

  return (
    <>
      <ShoulderItemStyledContainer className="kendo-pdf--prevent-split">
        <ShoulderItemTable
          header={
            <ShoulderItemHeader
              locations={locations}
              fullPrice={totalPrice}
              currency={currencyRoute}
              deliveryTime={shoulderOffer.delivery_time}
              transportType={transportTypes}
              order={order}
            />
          }
          rows={rowsData}
          columns={column}
        />
        <Typography variant={'subtitle1'} component={'div'} className={'offer-footer-info'}>
          {!!carrier && `${t('OfferDetail:Carrier')}: ${carrierName}`}
        </Typography>
      </ShoulderItemStyledContainer>
    </>
  );
};

export default WithRouteProductContext(ShoulderItem);
