import useMyRouting from '@context/useMyRouting/useMyRouting';
import getLocalizationByArgs from '@helpers/getLocalizationByArgs';
import restoreServiceData from '@pages/AdditionalServices/containers/methods/basket/restoreServiceData';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceSummaryProps } from '../ServiceSummary';

function SummaryTotal({ products, summary }: ServiceSummaryProps) {
  const { t } = useTranslation(['OfferDetail', 'Common', 'CustomsPayments']);
  const [settings] = useMyRouting().useSettings().useState();

  const restoredSetUpServices = products
    .map((s) => restoreServiceData(s))
    .filter((d) => !!d)
    .map((d) => d.setUpService);

  const restoredServices = products
    .map((s) => restoreServiceData(s))
    .filter((d) => !!d)
    .map((d) => [d.setUpService, ...d.otherServices])
    .flat(1);

  const customsPaymentService = restoredSetUpServices.find(
    (s) => s.ServiceData.template === 'customsPayments'
  );

  const customsPaymentsProducts =
    customsPaymentService?.TemporaryStore?.[customsPaymentService?.ServiceData.id]?.products ?? [];

  const addtionalServiceIds = customsPaymentService?.ServiceData.additionServices.map((s) => s.id);
  const filteredCalculations = restoredServices.filter((s) =>
    addtionalServiceIds.includes(s.ServiceData.id)
  );

  const totalProducts = customsPaymentsProducts.reduce(
    (prev, product) => {
      const { antiDumpingSum = 0, exciseSum = 0, feeSum = 0, vatSum = 0 } = product.total;
      return {
        feeSum: prev.feeSum + feeSum,
        exciseSum: prev.exciseSum + exciseSum,
        antiDumpingSum: prev.antiDumpingSum + antiDumpingSum,
        vatSum: prev.vatSum + vatSum,
      };
    },
    {
      feeSum: 0,
      exciseSum: 0,
      antiDumpingSum: 0,
      vatSum: 0,
    }
  );

  const customsSum = customsPaymentsProducts.reduce(
    (prev, item) => prev + (item.total?.customsSum ?? 0),
    0
  );

  if (!customsPaymentService) {
    return (
      <div className="a-service-basket--summary-tax">
        <div>
          <div>{t('CartSummary:VAT')}:</div>
          <div>
            {summary.Tax.toLocaleString()} {summary.TargetCurrency}
          </div>
        </div>
        <div>
          <div>{t('CartSummary:WithoutVAT')}:</div>
          <div>
            {summary.Price.toLocaleString()} {summary.TargetCurrency}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="a-service-basket--summary-tax">
      <div>
        <div>{t('CustomsPayments:CustomsSum')}:</div>
        <div>{customsSum.toLocaleString()} RUB</div>
      </div>
      <div>
        <div>{t('CustomsPayments:CustomsDuty')}:</div>
        <div>{totalProducts.feeSum.toLocaleString()} RUB</div>
      </div>
      <div>
        <div>{t('CustomsPayments:CustomsExcise')}:</div>
        <div>{totalProducts.exciseSum.toLocaleString()} RUB</div>
      </div>
      <div>
        <div>{t('CustomsPayments:AntidumpingDuty')}:</div>
        <div>{totalProducts.antiDumpingSum.toLocaleString()} RUB</div>
      </div>
      <div>
        <div>{t('CustomsPayments:CustomsVAT')}:</div>
        <div>{totalProducts.vatSum.toLocaleString()} RUB</div>
      </div>
      {filteredCalculations.length > 0 &&
        filteredCalculations.map((calc, index) => {
          const serviceName = getLocalizationByArgs(
            settings.primaryLanguageId,
            calc?.ServiceData?.name ?? '-',
            calc?.ServiceData?.localized_names ?? []
          );
          return (
            <div key={'customs-payment-total-' + index}>
              <div>{serviceName}:</div>
              <div>
                {calc.service.price_offer_result.result.fullPriceInTargetCurrency.toLocaleString()}{' '}
                RUB
              </div>
            </div>
          );
        })}
    </div>
  );
}

export { SummaryTotal };
