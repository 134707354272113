import { CHECK_EXCLUDE_KEYS, VALIDATE_CHECKS } from '../consts';
import { CustomsPaymentsTemplateProduct } from '../types';

export const checkErrorExisting = (keys: string[], errors: string[]) => {
  return keys.some((key) => errors.includes(key));
};

export const validateCustomsPaymentProducts = (
  products: CustomsPaymentsTemplateProduct[],
  filterErrorKeys: string[]
) => {
  return products.map((product) => {
    const isSpecialFieldsActive = typeof product.selectedExcise?.Prim === 'string';
	
    return Object.entries(product)
      .filter(([key]) => {
        if (!isSpecialFieldsActive) {
          return key !== 'maximumRetailPackagePrice' && key !== 'packagesCount';
        }
        return true;
      })
      .map(([key, value]) => {
        if (CHECK_EXCLUDE_KEYS.includes(key)) {
          return null;
        }

        const check = VALIDATE_CHECKS[key];

        if (check instanceof RegExp) {
          return !check.test(value) && key;
        } else if (typeof check === 'boolean') {
          return check !== value && key;
        }

        return null;
      })
      .filter(Boolean)
      .filter((errorKey) => filterErrorKeys.length === 0 || filterErrorKeys.includes(errorKey));
  });
};
