import { CartSummaryStyledContainer } from '@components/pages/CartSummary/StyledComponents';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import Typography from '@mui/material/Typography';
import { ServiceSummary } from '@pages/AdditionalServices/containers/methods/basket/getSummaryForServices';
import useBasket from '@pages/Basket/useBasket';
import { Order } from '@services/requests/orderService/interface';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Пропсы компонента
 */
export type CartSummaryProps = {
  order: Order;
  summary: ServiceSummary;
  className?: string;
  modification?: 'basket' | 'default';
  isMobile: boolean;
};

/**
 * Компонент вывода детализации по заказываемым услугам
 */
const CartSummary = (props: CartSummaryProps) => {
  const { summary, className, isMobile, modification = 'default', order } = props;
  const langCode = getLocalizationById(order.language_id).countryCode;

  const { t } = useTranslation('CartSummary', { lng: langCode });
  return (
    <CartSummaryStyledContainer className={clsx('cart-summary', className, modification)}>
      {modification === 'basket' && (
        <>
          <div className={clsx('summary-header-title', { 'is-mobile': isMobile })}>
            <div>
              <div>{t('CartSummary:Total')}:</div>
              <div className="summary-header-title--sub">{t('CartSummary:TotalSub')}</div>
            </div>
            <div>
              {summary.Total.toLocaleString()} {summary.TargetCurrency}
            </div>
          </div>
          <div className="summary-header-wrapper">
            {Object.keys(summary.Currencies).map((currency) => {
              return (
                <div className={'cart-summary__list-item --currency-item'} key={currency}>
                  <div className="summary-header-cost-text">{currency}:</div>
                  <div className="summary-header-cost">
                    {summary.Currencies[currency].toLocaleString()}
                  </div>
                </div>
              );
            })}
            {!!summary.Conversion && (
              <div className={'cart-summary__list-item --currency-item'}>
                <div className="summary-header-cost-text">
                  {t('CartSummary:ForeignExchangeExpenses')}:
                </div>
                <div className="summary-header-cost-text">
                  {summary.Conversion.toLocaleString()} {summary.TargetCurrency}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {modification !== 'basket' && (
        <ul className={'cart-summary__list --with-border-bottom'}>
          <li className={'cart-summary__list-item --currency-detail'}>
            <Typography variant={'body1'} className={'cart-summary__title'}>
              {t('CartSummary:CurrencyDetail')}:
            </Typography>
            <ul className={'cart-summary__sub-list'}>
              {Object.keys(summary.Currencies).map((currency) => {
                return (
                  <li className={'cart-summary__list-item --currency-item'} key={currency}>
                    {!modification ||
                      (modification === 'default' && (
                        <Typography variant={'body1'}>{currency}:</Typography>
                      ))}
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {summary.Currencies[currency].toLocaleString()}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </li>
          {!!summary.Conversion && (
            <li className={'cart-summary__list-item'}>
              <Typography variant={'body1'} className={'cart-summary__title'}>
                {t('CartSummary:ForeignExchangeExpenses')}:
              </Typography>
              <Typography variant={'body1'} whiteSpace={'nowrap'}>
                {summary.Conversion.toLocaleString()} {summary.TargetCurrency}
              </Typography>
            </li>
          )}
        </ul>
      )}
      <ul className={'cart-summary__list'}>
        <li className={'cart-summary__list-item'}>
          <Typography variant={'body1'} className={'cart-summary__title'}>
            {t('CartSummary:VAT')}:
          </Typography>
          <Typography variant={'body1'} whiteSpace={'nowrap'}>
            {summary.Tax.toLocaleString()} {summary.TargetCurrency}
          </Typography>
        </li>
        <li className={'cart-summary__list-item'}>
          <Typography variant={'body1'} className={'cart-summary__title'}>
            {t('CartSummary:WithoutVAT')}:
          </Typography>
          <Typography variant={'body1'} whiteSpace={'nowrap'}>
            {summary.Price.toLocaleString()} {summary.TargetCurrency}
          </Typography>
        </li>
      </ul>
    </CartSummaryStyledContainer>
  );
};

export default CartSummary;
