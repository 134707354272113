import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import ArchiveIcon from '@components/icons/Archive';
import EmailIcon from '@components/icons/EmailIcon';
import FavoriteIcon from '@components/icons/FavoriteIcon';
import QuestionIcon from '@components/icons/QuestionIcon';
import CartSummary from '@components/pages/CartSummary';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import { useBitrix } from '@context/useBitrixUser/useBitrix';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ServiceSummary } from '@pages/AdditionalServices/containers/methods/basket/getSummaryForServices';
import useBasket from '@pages/Basket/useBasket';
import { Order } from '@services/requests/orderService/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CartAsideBlockStyledContainer from './StyledComponent';

/**
 * Пропсы компонента
 */
type OrderAsideSummaryProps = {
  onSendEmailClick: () => void;
  onQuestionClick: () => void;
  onAddFavoriteClick: () => void;
  onGetPDFClick: () => void;
  summary: ServiceSummary;
  isMobile: boolean;
  order: Order;
};

/**
 * Компонент вывода информации и кнопок управления корзиной
 * @param props
 */
const CartAsideBlock = (props: OrderAsideSummaryProps) => {
  const [commentValue, setCommentValue] = useState<string>('');

  const [{ bitrixUser }] = useBitrix().useBitrixUser().useState();
  const { onAddFavoriteClick, onQuestionClick, onSendEmailClick, onGetPDFClick, summary, order } =
    props;
  const { t } = useTranslation('CartAsideBlock');

  const { setPage } = useMyRouting().actions;
  const { actions } = useBasket();
  const { completeOrder } = actions;

  const makeOrderHandler = async () => {
    if (!bitrixUser) {
      return;
    }

    const { id, first_name, email, phone } = bitrixUser;

    await completeOrder(id, first_name, email, phone, commentValue);

    setPage('success-order', true);
  };

  const changeCommentValueHandler = (newValue: string) => {
    setCommentValue(newValue);
  };

  return (
    <CartAsideBlockStyledContainer>
      <div className="controls">
        <CartSummary
          isMobile={props.isMobile}
          summary={summary}
          modification={'basket'}
          order={order}
        />{' '}
        <TextField
          fullWidth
          className="comment-field"
          size={'medium'}
          multiline
          type={'text'}
          label={t('CartAsideBlock:Comment')}
          variant="outlined"
          value={commentValue}
          onChange={(event) => changeCommentValueHandler(event.target.value)}
        />
        <Box className={'button-group'}>
          <YaTargetWrapper target="calculator_zakaz" noAuthTarget="">
            <BitrixDisableUserGuard>
              <Button
                onClick={makeOrderHandler}
                color={'primary'}
                variant={'contained'}
                fullWidth
                size={'large'}
              >
                {t('CartAsideBlock:OrderServices')}
              </Button>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
          <YaTargetWrapper target="calculator_pdf">
            <BitrixDisableUserGuard>
              <Button
                onClick={onGetPDFClick}
                color={'secondary'}
                variant={'contained'}
                size={'large'}
                fullWidth
              >
                {t('CartAsideBlock:DownloadPDF')}
              </Button>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
        </Box>
        <div className="links">
          <Button variant="text" onClick={onSendEmailClick} className={'link'} disabled>
            <EmailIcon />
            {t('CartAsideBlock:SendByEmail')}
          </Button>
          <Button variant="text" onClick={onQuestionClick} href="#" className={'link'} disabled>
            <QuestionIcon />
            {t('CartAsideBlock:AskQuestion')}
          </Button>
          <Button variant="text" onClick={onAddFavoriteClick} href="#" className={'link'} disabled>
            <FavoriteIcon />
            {t('CartAsideBlock:SaveToFavorites')}
          </Button>
          <Button variant="text" onClick={onAddFavoriteClick} href="#" className={'link'} disabled>
            <ArchiveIcon />
            {t('CartAsideBlock:AddToArchive')}
          </Button>
        </div>
      </div>
    </CartAsideBlockStyledContainer>
  );
};

export default CartAsideBlock;
