import { moneyRounding } from './price';

interface GetTotalCalcProps {
  isExport: boolean;
  feeRowData: Calculation;
  exciseRowData: Calculation;
  vatRowData: Calculation;
  antidumpingFeeRowData: Calculation;
  exportFeeRowData: Calculation;
  title: string;
}

interface Calculation {
  id: string;
  name: string;
  rate: string;
  rublesAmount: string;
}

function getTotalCalc({
  isExport,
  feeRowData,
  exciseRowData,
  antidumpingFeeRowData,
  vatRowData,
  exportFeeRowData,
  title,
}: GetTotalCalcProps) {
  const antidumpingRublesSum = +antidumpingFeeRowData?.rublesAmount || 0;
  const vatRublesSum = +vatRowData?.rublesAmount || 0;
  const exciseRublesSum = +exciseRowData?.rublesAmount || 0;
  const feeRublesSum = +feeRowData?.rublesAmount || 0;

  const totalRowData = {
    id: 'total',
    name: title,
    rate: '0%',
    rublesAmount: moneyRounding(
      moneyRounding(moneyRounding(feeRublesSum + vatRublesSum) + exciseRublesSum) +
        antidumpingRublesSum
    ).toString(),
  };

  const resultFee = isExport ? exportFeeRowData : feeRowData;

  const result = [resultFee, antidumpingFeeRowData, vatRowData, exciseRowData, totalRowData].filter(
    (row) => Boolean(row)
  );

  return result;
}

export { getTotalCalc };
