import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material';

export const CustomsPaymentsDetailingStyledContainer = styled('div')`
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .customs-payments-wrap {
    gap: 70px;
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  .heading {
    flex: 0 0 100%;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.values.laptop}px) {
      font-size: 22px;
    }
  }

  .customs-payments-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.46px;
    letter-spacing: 0.02em;
    color: #000;
    margin-bottom: 30px;
    text-align: justify;
  }

  .title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .overall-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
    }
  }

  .overall-col {
    max-width: 430px;
    width: 100%;
  }

  .overall-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }

    &__title {
      color: #000;
      font-weight: 600;
    }

    &__value {
      color: #000;
    }
  }

  .product-main-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      align-items: flex-start;
      gap: 60px;
    }
  }

  .product-main-col {
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 50%;
    }
  }

  .product-main-additional-tax {
    .product-main__col {
      @media (min-width: ${breakpoints.values.xsm}px) {
        &:first-of-type {
          width: 40%;
        }

        &:last-of-type {
          width: 60%;
          display: flex;
          justify-content: flex-start;
          text-align: left;
        }
      }
    }
  }

  .product-main {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #edf3fd;
    border-radius: 18px;
    gap: 20px;

    @media (min-width: ${breakpoints.values.sm}px) {
      gap: 0px;
      display: block;
    }

    &__row {
      display: flex;
      margin: -2px;
      flex-wrap: wrap;

      @media (min-width: ${breakpoints.values.sm}px) {
        margin: -10px;
      }
    }

    &__col {
      padding: 2px;
      width: 100%;

      @media (min-width: ${breakpoints.values.sm}px) {
        padding: 10px;
      }

      &:first-of-type {
        font-weight: 600;
      }

      @media (min-width: ${breakpoints.values.sm}px) {
        &:first-of-type {
          width: 70%;
        }

        &:last-of-type {
          width: 30%;
          display: flex;
          justify-content: flex-end;
          text-align: right;
        }
      }
    }
  }

  .offer-product {
    margin-bottom: 24px;

    .title {
      margin-top: 50px;

      &__cargo-name {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .offer-group-header {
      &__container-rental {
        grid-template-columns: 40px 1fr auto;
        gap: 15px;
      }
    }
  }

  .unloading-loading {
    overflow-y: auto;
    display: grid;
    grid-template-columns: minMax(285px, 1fr) 7fr;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};

    &.--border-bottom {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }

    &__section {
      padding: 10px 20px;
      border-top: 0;
      display: flex;
      align-items: center;

      &:first-of-type {
        border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
      clear: both;
    }
  }

  .add-info {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.grey[800]};
  }

  .table-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: ${breakpoints.values.md}px) {
      gap: 20px;
    }
  }

  .offer-footer-info {
    &__heading {
      display: flex;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.palette.grey[100]};
      padding: 20px;

      @media (max-width: ${breakpoints.values.sm}px) {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }
    }
  }

  /* @media (max-width: ${breakpoints.values.sm}px) {
    padding: 30px 15px;
  } */

  .table-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: ${breakpoints.values.md}px) {
      gap: 20px;
    }
  }

  .bottom-caption {
    margin-top: 20px;
    color: ${({ theme }) => theme.palette.grey[300]};
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  .summary-wrapper {
    border-radius: 18px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  @media print {
    padding: 20px;
  }

  .logo {
    width: 100%;
    margin-bottom: 30px;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    color: ${palette.light.common.black};

    &__until-date,
    &__order {
      font-weight: 400 !important;
      color: #000 !important;
      font-size: 16px !important;
      font-style: normal !important;
      line-height: normal !important;
    }
  }

  .product-list-product {
    opacity: 1;
    transition: 1s opacity;

    &.rendering {
      opacity: 0;
    }
  }

  .offer-group-header {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 45px;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: ${palette.light.blue[300]};
    border-radius: 18px 18px 0 0;

    &__service {
      grid-template-columns: 1fr auto;
    }

    @media (max-width: ${breakpoints.values.md}px) {
      grid-gap: 15px;
    }

    &__right {
      color: black;
      font-size: 16px;
      white-space: nowrap;
    }

    .MuiTypography-subtitle1 {
      @media (max-width: ${breakpoints.values.sm}px) {
        font-size: 14px;
        text-align: center;
      }
    }

    .price {
      white-space: nowrap;
    }

    &__title {
      display: flex;
      gap: 20px;
      align-items: center;

      @media (max-width: ${breakpoints.values.sm}px) {
        flex-direction: column;
      }
    }
  }

  .offer-accordion-group {
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-radius: 18px;
    overflow: hidden;

    .MuiDataGrid-root {
      border: none;
    }

    &__body > div {
      &:last-child {
        .MuiDataGrid-row--lastVisible {
          .MuiDataGrid-cell {
            border-bottom: 0;
          }
        }

        .unloading-loading {
          &__section {
            border-bottom: 0;
          }
        }
      }
    }

    footer {
      padding: 0;
    }
  }

  .transport-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: ${palette.light.common.white};
    filter: drop-shadow(0px 1px 3px rgba(47, 59, 69, 0.3));
    border-radius: 50%;

    svg {
      width: 26px;
      height: 26px;

      path {
        fill: ${palette.light.green[300]};
      }
    }
  }

  .result-wrap {
    display: flex;
    gap: 20px;
    margin-top: 40px;
    flex-direction: column;

    @media (min-width: ${breakpoints.values.xsm}px) {
      display: grid;
      gap: 60px;
      flex-direction: row;
      grid-template-columns: 1fr 1fr;
    }
  }

  .count-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    margin-top: 30px;
  }

  .result-item {
    width: 100%;

    &__row {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-bottom: 10px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        flex-direction: row;
        gap: 60px;
        margin-bottom: 0px;
      }
    }

    &__param {
      display: flex;
      justify-content: space-between;
      position: relative;
      /* margin-bottom: 20px; */

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        content: '';
        height: 1px;
        background-color: #dbe0ec;
        cursor: pointer;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        margin-bottom: 0;
      }

      .MuiInput-input {
        color: rgb(56, 56, 56) !important;
        text-align: right !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        padding: 4px 5px;
      }
      /* margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      } */
    }

    &__input {
      margin-bottom: 20px;
    }

    &__cost {
      margin-bottom: 10px;
    }

    &__cost-value {
      text-align: start;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        text-align: end;
      }
    }

    &__cost-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }

    .result-item__table {
      width: 100%;
      border: 1px solid #dbe0ec;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .product-help-wrap {
    margin-top: 20px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-top: 70px;
    }
  }

  .product-help-control {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @media (min-width: ${breakpoints.values.xsm}px) {
      justify-content: flex-start;
      margin-right: 20px;
    }
  }

  .product-help-control-btn {
    display: flex;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    color: ${palette.light.blue[400]};
    border-bottom: 1px solid transparent;
    transition: 400ms;
    font-size: 14px;

    svg {
      color: ${palette.light.blue[400]};
    }

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-right: 20px;
      font-size: 12px;
    }

    &:hover {
      color: #224179;
      border-bottom: 1px solid ${palette.light.blue[400]};
    }
  }

  .product-help-list {
    margin-top: 20px;
  }

  .product-item {
    background: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px #0005730d;

    padding: 20px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      padding: 6px 50px 6px 36px;
    }

    &__notes-wrap {
      display: flex;
      flex-direction: column;
    }

    &__header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.46px;
      letter-spacing: 0.02em;
      color: #000;
    }

    &__body {
      display: none;
      padding: 20px 0;

      @media (min-width: ${breakpoints.values.xsm}px) {
        padding: 20px 70px 30px 70px;
      }

      &.expanded {
        display: block;
      }
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 23.46px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
      color: #000;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const SummaryItemStyledContainer = styled('section')`
  display: grid;
  grid-template-columns: auto auto;
  padding: 18px 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.success.main};
  border-radius: 18px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.values.xsm}px) {
    padding: 16px;
    text-align: center;
    flex-direction: column;
  }

  .value {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.6px;

    @media (max-width: ${breakpoints.values.laptop}px) {
      font-size: 22px;
    }
  }
`;
