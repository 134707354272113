import {
  BaseServiceInitialStateGenerator
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/baseServiceInitialStateGenerator";
import {
  VerticalTableTemplateProcessor
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/verticalTableTemplateProcessor";
import { CustomsPaymentsTemplateProcessor } from "./customsPaymentsTemplateProcessor";

// Фабрика сервиса
const serviceInitialStateGenerator = () => new BaseServiceInitialStateGenerator([
  new VerticalTableTemplateProcessor,
  new CustomsPaymentsTemplateProcessor,
])

export default serviceInitialStateGenerator