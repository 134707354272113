import TerminalsInfoGroupTitle from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoGroupTitle';
import TerminalsInfoRowIncludedAllowance from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoRowIncludedAllowance';
import { Order } from '@services/requests/orderService/interface';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React, { FC } from 'react';

interface Props {
  id: string;
  branchItem: BranchItem;
  firstTerminal: boolean;
  lastTerminal: boolean;
  isIncluded: boolean;
  order: Order;
}

/**
 * Компонент выводит данные при отсутствии надбавок
 */
const TerminalsInfoWithFullIncludedAllowance: FC<Props> = ({
  id,
  branchItem,
  firstTerminal,
  lastTerminal,
  isIncluded,
  order,
}) => {
  let type = 'loading/unloading';
  let included = true;
  if (firstTerminal) {
    type = 'load';
    included = isIncluded;
  }
  if (lastTerminal) {
    type = 'unload';
    included = isIncluded;
  }

  return (
    <div>
      <TerminalsInfoGroupTitle id={id} branchItem={branchItem} order={order} />
      <TerminalsInfoRowIncludedAllowance type={type} isIncluded={included} order={order} />
    </div>
  );
};

export default TerminalsInfoWithFullIncludedAllowance;
