import {
  ExciseItem,
  ExportItem,
  ImportItem,
  VatItem,
} from '@services/requests/customsTaxService/interface';

export interface AdditionalTax {
  fees: ImportItem[];
  vats: VatItem[];
  excises: ExciseItem[];
  exportFees: ExportItem[];
}

export function isResponseValidateErrorPredicate(object: object): object is Error {
  return 'Error' in object;
}
