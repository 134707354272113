import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { ChildrenLocationQuery, ChildrenLocationQueryResponse } from './ChildrenLocationsQuery';
import {
  LocationsAndTerminalSearchQuery,
  LocationsAndTerminalSearchQueryResult,
  SearchSource,
} from './LocationsAndTerminalSearchQuery';
import { LocationsAndTerminalSearchServiceInterface, SearchResult } from './types';

/**
 * Сервис поиска локаций и терминалов по строке поискового запроса
 */
export class LocationsAndTerminalSearchService
  implements LocationsAndTerminalSearchServiceInterface
{
  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  /**
   * Поиск локаций и терминалов по части названия
   * @param searchString
   */
  async SearchLocationsAndTerminals(
    searchString: string,
    searchSource: SearchSource
  ): Promise<SearchResult[]> {
    try {
      if (searchString.length === 0) {
        return [];
      }

      const resp = await this.client.Query<null, LocationsAndTerminalSearchQueryResult>(
        new LocationsAndTerminalSearchQuery(searchString, searchSource),
        {}
      );
      return resp.searchLocationsAndTerminals.map(
        (value) =>
          ({
            id: value.entityId,
            type: value.entityType === 'Location' ? 'location' : 'terminal',
          } as SearchResult)
      );
    } catch (e) {
      $error.next(e);
    }
  }

  /**
   *
   * @param id
   * @constructor
   */
  async GetChildrenForLocation(id: string): Promise<SearchResult[]> {
    try {
      const resp = await this.client.Query<{ id: string }, ChildrenLocationQueryResponse>(
        new ChildrenLocationQuery(id),
        {}
      );

      return resp.childLocation.map<SearchResult>((value) => ({
        id: value.id,
        type: 'location',
      }));
    } catch (e) {
      $error.next(e);
    }
  }
}
