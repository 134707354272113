import { ServiceFieldSettings } from '@onlog-public/additional-services-types';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface FieldLeftTextProps {
  fieldSettings: ServiceFieldSettings;
}

const FieldLeftText: FC<FieldLeftTextProps> = (props) => {
  const { fieldSettings } = props;

  const { t } = useTranslation();

  const textWidth = 12 - fieldSettings.FieldWidth;

  if (
    !fieldSettings.IsNeedDisplayFieldLeftText ||
    fieldSettings.FieldLeftText.length === 0 ||
    textWidth === 0
  ) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        'addition-service-field',
        'a-service-field-left-text',
        `field-size-${textWidth}`
      )}
    >
      <span>{t(fieldSettings.FieldLeftText)}</span>
    </div>
  );
};

export default FieldLeftText;
