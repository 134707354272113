import { Service } from '@onlog-public/additional-services-types';
import { moneyRounding } from '@pages/AdditionalServices/components/Service/CustomsPaymentsTemplate/helpers/price';
import { CustomsPaymentsTemplateStore } from '@pages/AdditionalServices/components/Service/CustomsPaymentsTemplate/types';
import { serviceToCalculateGeneratorProcessor } from '@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface';
import { ServicePriceCalculationItemsProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { v4 } from 'uuid';

/**
 * CustomsPaymentsProcessor реализует процессор генерации ЦП для вертикальных
 * таблиц. Генерирует ЦП для таможенных сборов.
 */
export class CustomsPaymentsProcessor implements serviceToCalculateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для переданной услуги.
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === 'customsPayments';
  }

  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным.
   *
   * @param service
   * @param currency
   * @param fieldValues
   * @param serviceValues
   * @param serviceState
   */
  Generate(
    service: Service,
    currency: string,
    fieldValues: { [x: string]: number },
    serviceValues: { [x: string]: number },
    serviceState: any
  ): ServicePriceCalculationItemsProps[] {
    const state: CustomsPaymentsTemplateStore = serviceState;

    const values = { ...fieldValues, ...serviceValues };

    const additionalServicesToCalculate = service?.additionServices ?? [];
    if (0 === additionalServicesToCalculate.length) {
      return [];
    }

    const baseTotalCost = state.products.reduce((prev, product) => {
      const { antiDumpingSum, exciseSum, feeSum, vatSum } = product.total;

      return (
        prev +
        moneyRounding(
          moneyRounding(moneyRounding((antiDumpingSum || 0) + (exciseSum || 0)) + (feeSum || 0)) +
            (vatSum || 0)
        )
      );
    }, 0);

    values[service.settings.TemplateSettings.CustomsPayments.BaseTotalCostField] = baseTotalCost;

    const mapVal = (values: { [x: string]: number }) => (k: string) => ({
      key: k,
      value: String(values[k]),
    });

    return [
      {
        stateID: v4(),
        currency_id: currency,
        service_id: service.id,
        values: Object.keys(values).map(mapVal(values)),
      },
      ...additionalServicesToCalculate.map((s) => ({
        stateID: v4(),
        currency_id: '1',
        service_id: s.id,
        values: Object.keys(values).map(mapVal(values)),
      })),
    ];
  }
}
