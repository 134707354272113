import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

export interface HandbookVersionItemData {
  id: string
  name: string
  localized_names: string[]
  ordinal: number
  value: number
}

// Данные валюты
export interface HandbookVersionData {
  id: string
  name: string
  items: HandbookVersionItemData[]
}

export interface HandbookVersionLoaderQueryResponse {
  items: HandbookVersionData[];
}

/**
 * Запрос загрузки данных по валют
 */
export class HandbookVersionLoaderQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

    constructor(ids?: any[]) {
      this.variables = null;
      this.query = gql`
            query LoadHandbookVersion {
                items: handbookpublished_list(
                    limit: 1000000
                    ${
                      ids && ids.length > 0
                        ? `where:{id:{_in: [${ids.map((id) => `"${id}"`).join(',')}]}}`
                        : ``
                    }
                ) {
                    id
                    name
                    items {
                        id
                        name
                        localized_names
                        ordinal
						value
                    }
                }
            }
        `;
    }
}
